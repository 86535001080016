/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:c2e832fc-5fa6-4bea-8277-c1c68c1e6995",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_gf9V3naHF",
    "aws_user_pools_web_client_id": "5kt3lf2dnt9frjdsde9pi3as98",
    "oauth": {}
};


export default awsmobile;

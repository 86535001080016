import React from 'react';
import Container from './Container'
import protectedRoute from './protectedRoute'

function Settings() {
  return (
    <Container>
      <h1>Preferences</h1>
    </Container>
  );
}

export default protectedRoute(Settings)

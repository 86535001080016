// import {getIPv4} from './misc/bundle.dev.js'
import React, { useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import Form from './Form'
import {useNavigate} from 'react-router-dom'

function Login(props) {
    const navigate = useNavigate();
    const [user, setUser] = useState(null)

  useEffect(()  => {
    // set our variable to true
    let isApiSubscribed = true;
    Auth.currentUserPoolUser()
    .then((userData) => {
      if (isApiSubscribed) {
        setUser(userData)
        localStorage.setItem("userEmail", userData.attributes.email)
      }
      Hub.listen('auth', (data) => {
        const { payload } = data
        if (payload.event === 'signOut') {
          setUser(null)
        }
      })
    })
    .catch((err) => console.log(err))
    return () => {
      isApiSubscribed = false
      if (user !== null) {
        navigate('/home')
      }
      
    }
  },[user])



  // const sendLogin = async (userData) => {
  //   await fetch(process.env.REACT_APP_BASE_URL + "writeLoginAttempt", {
  //     body: JSON.stringify(
  //       {
  //         'emailAddr':userData.attributes.email
  //       }
  //     ),
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     method: "POST"
  //   })
  //   .then((response) => response.json())
  //   .then((responseJSON) => console.log("WriteLoginLog: "+ responseJSON))
  // }

      return <Form setUser={setUser} />
}

export default Login

import React, { useState, useEffect } from 'react'
import { Button,Modal,notification  } from 'antd'
import { Auth, Hub } from 'aws-amplify'
import Container from './Container'
import Form from './Form'
import { useNavigate } from 'react-router-dom'

function Profile(props) {
  const navigate = useNavigate();
  const [isPwModalUp, setisPwModalUp] = useState(false);
  const [oldPw, setOldPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [CNewPw, setCNewPw] = useState('');

  const showModal = () => {
    setisPwModalUp(true);
  };

  const handleOk = async () => {
    if (newPw === CNewPw && newPw.length > 0 && newPw.length > 0) {
      console.log("Passwords Match")
      try {
        const user=await Auth.currentAuthenticatedUser({bypassCache: false});
        //ToDo: check if old password is valid or not. 
        const result=await Auth.changePassword(user, oldPw, newPw);
        if (result === "SUCCESS") {
          openNotification()
          setCNewPw('')
          setOldPw('')
          setNewPw('')
        }
        props.history.push('profile');  
      } 
      catch (error) {
        console.log('error:', error);
      }
      setisPwModalUp(false);
      }
      else {
        console.log("Passwords Dont Match")
      }

  };

  const handleCancel = () => {
    setisPwModalUp(false);
  };

  const openNotification = () => {
    notification.open({
      message: 'Password Changed',
      description:
        'Your password has been successfully updated',
      onClick: () => {
        notification.close()
      },
    });
  };
  useEffect(()  => {
    checkUser()
    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signOut') {
        setUser(null)
        navigate('/')
      }
    })
  }, [])
  const [user, setUser] = useState(null)

  async function checkUser() {
    try {
      const data = await Auth.currentUserPoolUser()
      const userInfo = { username: data.username, ...data.attributes }
      setUser(userInfo)
    } catch (err) { console.log('error: ', err) }
  }

  function signOut() {
    localStorage.removeItem('project')
    Auth.signOut()
      .catch(err => console.log('error signing out: ', err))
      
  }

  if (user) {
    return (
      <Container>
        <h1>Profile</h1>
        <h3>Email: {user.email}</h3>
        <h4>Phone: {user.phone_number}</h4>
         <div style={{paddingTop:20}}>
          <Button onClick={showModal}>Change Password</Button>
        </div>
        <div style={{paddingTop:20}}>
          <Button onClick={signOut}>Sign Out</Button>
        </div>
        <Modal title="Change Password" visible={isPwModalUp} onOk={handleOk} onCancel={handleCancel}>
          <div style={{width:'80%', paddingBottom:20, marginBottom:20}}>
            <div style={{width:'33%', float:'left'}}>
              <label>Current Password: </label>
            </div>
            <div style={{width:'50%', float:'right'}}>
              <input 
                id="oldPw" 
                type="password" 
                onChange={(evt) => { setOldPw(evt.target.value); }} 
              />
            </div>
          </div>

          <div style={{width:'80%', paddingBottom:20, marginBottom:20}}>
            <div style={{width:'33%', float:'left'}}>
              <label>New Password: </label>
            </div>
            <div style={{width:'50%', float:'right'}}>
            <input 
                id="newPw" 
                type="password" 
                onChange={(evt) => { setNewPw(evt.target.value); }} 
              />
            </div>
          </div>


          <div style={{width:'80%', paddingBottom:20, marginBottom:20}}>
            <div style={{width:'33%', float:'left'}}>
              <label>Confirm Password: </label>
            </div>
            <div style={{width:'50%', float:'right'}}>
            <input 
                  id="CNewPw" 
                  type="password" 
                  onChange={(evt) => { setCNewPw(evt.target.value); }} 
                />
            </div>
          </div>
        </Modal> 
      </Container>
    );
  }
  else {
    return <Form setUser={setUser} />
  }
}

export default Profile

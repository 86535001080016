import React from 'react'
import { styles } from './Form'
import DCMLogo from './images/logoDCM2.png'
import {Image, message,Button, Tooltip} from 'antd'

function SignIn({ signIn, updateFormState }) {
  return (
    <div style={styles.container}>
      <Tooltip title={process.env.REACT_APP_VERSION}>
        <Image
          src={DCMLogo}
          preview={false}
        />
      </Tooltip>
      <input
        name='username'
        onChange={e => {e.persist();updateFormState(e)}}
        style={styles.input}
        placeholder='email'
      />
      <input
        name='password'
        type='password'
        onChange={e => {e.persist();updateFormState(e)}}
        style={styles.input}
        placeholder='password'
      />
      <Button onClick={signIn}>Sign In</Button>
    </div>
  )
}

export default SignIn

import React,{useEffect, useState, useRef} from 'react'
import protectedRoute from '../protectedRoute'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { TileLayout } from "@progress/kendo-react-layout";
import { AlertOutlined, CheckCircleOutlined, CloseOutlined, DatabaseOutlined, EditOutlined, InfoOutlined, PhoneOutlined,
          PoweroffOutlined, MailOutlined,  MessageOutlined, UploadOutlined, 
          UserAddOutlined, UserSwitchOutlined  } from '@ant-design/icons'
import { Steps, Image, Input, Form, Modal, Checkbox, DatePicker, Select, Tooltip, notification } from 'antd';
import moment from 'moment';
import './Claim.css';
import { Auth } from 'aws-amplify';

import {getUserToken} from '../misc/authHelper'
const { Option } = Select;
//============================================
// CODE BLOCKS
//============================================
//  CLAIMHOME props
//  useEffect
//  getClaim
//  CLIENT PRODUCT DATA
//  PARTNERS AND PARTNERPERSONS DATA
//  CLAIMROLES DROPDOWN DATA
//  SYSCODE DATA
//  STYLES
//  searchBox
//  claimHeader
//  renderClaimValue
//  renderClaimDetails
//  renderClaimMedicalDetails
//  EditClaimForm
//  _setEditClaimDataItem
//  renderClaimRoles
//  _getClaimRoleData
//  ClaimRoleForm
//  _setClaimRoleDataItem
//  EditStepForm
//  _setEditStepDataItem
//  renderSteps
//  renderComplaints
//  renderServiceCosts
//  LOG FILTERS AND ICONS
//  renderTileHeader
//  SEARCH LOGS
//  renderLogs
//  setFormVisible
//  EditLogForm
//  _setEditLogDataItem
//  renderReminders
//  renderDocFiles
//  renderLatestImages
//  renderDocFolders
//  _sendEmail
//  EmailForm
//  _setEmail
//  _sendSMS
//  SMSForm
//  positions4K and claimPositions
//  claimTiles and tiles4K
//  handleReposition
//  MAIN RENDER
//============================================


//=======================================================================
//  CLAIMHOME props
//=======================================================================
function ClaimHome(props) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [userName, setUserName] = useState(localStorage.getItem('userEmail'))

  // let claimID
  var claimID = localStorage.getItem("claimId")
  const [ClaimID, setClaimID] = useState(claimID)
 
  // const [ClaimID, setClaimID] = useState(location.state.claimId)

  const [isLoading, setIsLoading] = useState(true)
  const [currClaim, setCurrClaim] = useState([])
  const [currEditClaim, setCurrEditClaim] = useState('')
  const [currEditClaimRole, setCurrEditClaimRole] = useState('')
  const [isEditClaimFormVisible, setEditClaimFormVisible] = useState(false);
  const [isComplaintFormVisible, setComplaintFormVisible] = useState(false);
  const [isClaimRoleFormVisible, setClaimRoleFormVisible] = useState(false);

  const [isNewProviderSubFormVisible, setNewProviderSubFormVisible] = useState(false);
  const [isNewPartnerPersonSubFormVisible, setNewPartnerPersonSubFormVisible] = useState(false);

  const [isEditLogFormVisible, setEditLogFormVisible] = useState(false);
  const [currClaimLogID, setClaimLogID] = useState(false)
  
  const [isEditReminderFormVisible, setEditReminderFormVisible] = useState(false);
  const [currReminderID, setReminderID] = useState(false)

  const [isEditStepFormVisible, setEditStepFormVisible] = useState(false);
  const [step, setStep] = useState(false)
  const [currStep, setCurrStep] = useState()

  const [isEmailFormVisible, setEmailFormVisible] = useState(false);
  const [isSmsFormVisible, setSmsFormVisible] = useState(false);

  const { Search } = Input;
  const [searchString, setSearchString] = useState('') 
  const [searchComms, setSearchComms] = useState('') 
  const [searchLogs, setSearchLogs] = useState('')   

  const { TextArea } = Input;
  const { Option } = Select;

  const inputFields = { color:'blue' }

  
  //Tiles State Data
  const [claimHdrData, setClaimHdrData] = useState([]) 
  const [logData, setLogData] = useState([])
  const [remindersData, setRemindersData] = useState([])
  const [docfilesTileData, setdocfilesTileData] = useState([])
  const [servicecostsTileData, setServicecostsTileData] = useState([])
  const [allDocsData, setAllDocsData] = useState([])
  // const [docfoldersTileData, setDocfoldersTileData] = useState([])
  // const [complaintsData, setComplaintsData] = useState([])
  // const [appointmentsData, setAppointmentsData] = useState([])
  // const [linksData, setLinksData] = useState([])
  const [stepsData, setStepsData] = useState([])
  // const [commData, setCommData] = useState([])
  // const [disbsData, setDisbsData] = useState([])
  
  const [claimrolesData, setClaimrolesData] = useState([])
  const [dcmPeopleData, setDcmPeopleData] = useState([])

  const [emailData, setEmailData] = useState([])

  const [filteredLogs, setFilteredLogs] = useState(logData)
  
  const [currClaimRole, setCurrClaimRole] = useState()
  const [currReminder, setCurrReminder] = useState([])
  const [globPartnersData, setGlobPartnersData] = useState([]);
  const [globPartnerPersonsData, setGlobPartnerPersonsData] = useState([]);


  //=======================================================================
  //  useEffect
  //=======================================================================
  useEffect(() => {

    _getClaim()

  },[ClaimID])

  
  //=======================================================================
  //  getClaim
  //=======================================================================
  const _getClaim = async () => {
    console.log("_getClaim", ClaimID)
    if(!ClaimID) {
      let claimId = localStorage.getItem("claimId")
      setClaimID(claimId)
    }
    // localStorage.setItem('claimId', ClaimID)

    setIsLoading(true)

    // var UserEmail = "system.admin@davidcoreymedical.com"
    var UserEmail = localStorage.getItem("userEmail")
    console.log(UserEmail)

    let tmpClaimInfo
    setIsLoading(true)
    await fetch(process.env.REACT_APP_BASE_URL + "getClaim", {
      body: JSON.stringify(
        {
          loginId: UserEmail,
          action: "",
          claimId: ClaimID.toString()
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => tmpClaimInfo = responseJSON)
    setCurrClaim(tmpClaimInfo)

    console.log(tmpClaimInfo)

    setClaimHdrData(tmpClaimInfo[0])
    setUserName(tmpClaimInfo[0][0].UserName)

    setCurrEditClaim({
      modifier: 'public',
      ProdShortName: tmpClaimInfo[0][0].ProdShortName,
      ProductID: tmpClaimInfo[0][0].ProductID,
      ClaimNo: tmpClaimInfo[0][0].ClaimNo,
      IW_Title: tmpClaimInfo[0][0].IW_Title,
      IW_Given: tmpClaimInfo[0][0].IW_Given,
      IW_Middle: tmpClaimInfo[0][0].IW_Middle,
      IW_Surname: tmpClaimInfo[0][0].IW_Surname,
      IW_PhoneH: tmpClaimInfo[0][0].IW_PhoneH,
      IW_Cell: tmpClaimInfo[0][0].IW_Cell,
      IW_email: tmpClaimInfo[0][0].IW_email,
      IW_Address1: tmpClaimInfo[0][0].IW_Address1,
      IW_Address2: tmpClaimInfo[0][0].IW_Address2,
      IW_City: tmpClaimInfo[0][0].IW_City,
      IW_State: tmpClaimInfo[0][0].IW_State,
      IW_Jurisdiction: tmpClaimInfo[0][0].IW_Jurisdiction,
      IW_Zipcode: tmpClaimInfo[0][0].IW_Zipcode,
      IW_DOB: tmpClaimInfo[0][0].IW_DOB,
      IW_Gender: tmpClaimInfo[0][0].IW_Gender,
      IW_Height: tmpClaimInfo[0][0].IW_Height,
      IW_Weight: tmpClaimInfo[0][0].IW_Weight,
      IW_SSN: tmpClaimInfo[0][0].IW_SSN,
      IW_Preferred: tmpClaimInfo[0][0].IW_Preferred,
      IW_PrefLang: tmpClaimInfo[0][0].IW_PrefLang,
      IWC_Title: tmpClaimInfo[0][0].IWC_Title,
      IWC_Given: tmpClaimInfo[0][0].IWC_Given,
      IWC_Middle: tmpClaimInfo[0][0].IWC_Middle,
      IWC_Surname: tmpClaimInfo[0][0].IWC_Surname,
      IWC_PhoneH: tmpClaimInfo[0][0].IWC_PhoneH,
      IWC_Cell: tmpClaimInfo[0][0].IWC_Cell,
      IWC_email: tmpClaimInfo[0][0].IWC_email,
      IWC_Relship: tmpClaimInfo[0][0].IWC_Relship,

      IW_HospitalName: tmpClaimInfo[0][0].IW_HospitalName,
      IW_HospitalRoomNo: tmpClaimInfo[0][0].IW_HospitalRoomNo,

      IW_ICDCode: tmpClaimInfo[0][0].IW_ICDCode,
      MED_Diagnosis: tmpClaimInfo[0][0].MED_Diagnosis,
      MED_Rx: tmpClaimInfo[0][0].MED_Rx,
      MED_DrOrders: tmpClaimInfo[0][0].MED_DrOrders,
      MED_Other: tmpClaimInfo[0][0].MED_Other,
      DCH_Comments: tmpClaimInfo[0][0].DCH_Comments,
      IW_Comments: tmpClaimInfo[0][0].IW_Comments,

      IW_DOBDT: tmpClaimInfo[0][0].IW_DOBDT,
      MED_InjuryDT: tmpClaimInfo[0][0].MED_InjuryDT,
      DCH_DischargeDT: tmpClaimInfo[0][0].DCH_DischargeDT,
    })
   
    setLogData(tmpClaimInfo[1])
    setClaimrolesData(tmpClaimInfo[2])
    
    setRemindersData(tmpClaimInfo[3])
    setdocfilesTileData(tmpClaimInfo[4])
    setAllDocsData(tmpClaimInfo[5])
    // setDocfoldersTileData(tmpClaimInfo[6])
    // setComplaintsData(tmpClaimInfo[7])
    // setAppointmentsData(tmpClaimInfo[8])
    setStepsData(tmpClaimInfo[9])
    setServicecostsTileData(tmpClaimInfo[11])
    console.log(tmpClaimInfo[11])

    setGlobPartnersData(tmpClaimInfo[12])
    setGlobPartnerPersonsData(tmpClaimInfo[13])

    //Update the filtered state in hopes it renders on load and not refresh
    setFilteredLogs(tmpClaimInfo[1].slice(0, 20))

    localStorage.setItem('claimData', JSON.stringify(tmpClaimInfo[0]))
    localStorage.setItem('claimrolesData', JSON.stringify(tmpClaimInfo[2]))
    localStorage.setItem('partnerData', JSON.stringify(tmpClaimInfo[12]))
    localStorage.setItem('partnerPersonsData', JSON.stringify(tmpClaimInfo[13]))

    setDcmPeopleData(tmpClaimInfo[14])

    setIsLoading(false)
  }

    //=======================================================================
  //  CLIENT PRODUCT DATA
  //=======================================================================
  const clientProductsData = require("../data/ClientProducts.json");
  // console.log(clientProductsData);
  const renderClientProducts = ( records ) => {
    return (
      <Select style={inputFields} showSearch placeholder={"Select the Carrier ..."} >
        {records.map((line, i) => <Option key={i} value={line.ProductID}>{line.Carrier}</Option>)}
    </Select>
    )
  };


  const renderPartnerPersons = ( records ) => {
    return (
      <Select style={inputFields} showSearch placeholder={"Select an already-listed Person for this Organization ..."} >
        {records.map((line, i) => <Option key={i} value={line.PersonID}>{line.LastName}, {line.FirstName}</Option>)}
      </Select>
    )
  };


  //=======================================================================
  //  CLAIMROLES DROPDOWN DATA
  //=======================================================================
  const renderClaimRolesDropdownList = ( records ) => {
    return (
      <Select style={inputFields} showSearch >
        {records.map((line, i) => <Option key={i} value={line.ClaimRoleID}>
          {line.Person}, {line.Company} [{line.RoleTypeStr}]</Option>)}
    </Select>
    )
  };

  const renderClaimRolesPersonDropdownList = ( records ) => {
    return (
      <Select style={inputFields} showSearch labelInValue >
        {records.map((line, i) => <Option key={i} value={line.PersonID}>
          {line.Person}, {line.Company} [{line.RoleTypeStr}]</Option>)}
    </Select>
    )
  };

  const renderDcmPeopleDropdownList = ( records ) => {
    return (
      <Select 
        style={inputFields} 
        showSearch 
        labelInValue
        onChange={setPersonIDValue}
        >
        {records.map((line, i) => <Option key={line.PersonID} value={line.PersonID}>
          {line.ShowName}</Option>)}
    </Select>
    )
  };

  const setPersonIDValue = (value) => {
    sessionStorage.setItem('personID', value.key)
  }


  const renderClaimRolesEmailList = ( records ) => {
    return (
      <Select showSearch mode="tags" allowClear style={{ width: '100%', color:'blue' }}
        placeholder="Select from the dropdown or type in an email address" >
        {records.map((line, i) => <Option key={i} value={line.email}>
          {line.email} [{line.Person}]</Option>)}
    </Select>
    )
  };

  const renderClaimRolesCellList = ( records ) => {
    return (
      <Select showSearch mode="tags" allowClear style={{ width: '100%', color:'blue' }}
        placeholder="Select from the dropdown or type in an email address" >
        {records.map((line, i) => <Option key={i} value={line.Mobile}>
          {line.Mobile} [{line.Person}]</Option>)}
    </Select>
    )
  };


  //=======================================================================
  //  SYSCODE DATA
  //=======================================================================
  const sysCodesData = require("../data/SysCodes.json");
  const sysCodes = sysCodesData[0];
  const renderSysCodes = ( codeType, label ) => {
    const records = sysCodes.filter( (sysCodes) => sysCodes.xsType == codeType)
    return (
      <Select style={inputFields} showSearch placeholder={label}>
        <Option value=""></Option>)
        {records.map((line, i) => <Option key={i} value={line.xsCode}>{line.xsDescr}</Option>)}
    </Select>
    )
  };

 
  //=======================================================================
  //  STYLES
  //=======================================================================
  // TILE COLORS
  const white = '#ffffff'
  const lightBlue = '#F8F8FF'
  const lightPurple = '#f4ecf7'
  const lightGreen = '#fbfcf4'
  const lightYellow = '#fef5e7' 
  const lightOrange = '#fbe9e7'
  const lightGray = '#f2f4f4'
  const lightSalmon = '#fdf2e9'
  const lightPink = '#fdf8f7'
  const navy = 'navy'

  const bgColor = {
    white: {backgroundColor: white},
    lightGray: {backgroundColor: lightGray},
    lightYellow: {backgroundColor: lightYellow},
    lightBlue: {backgroundColor: lightBlue},
    lightPink: {backgroundColor: lightPink},
    lightGreen: {backgroundColor: lightGreen}
  };

  // STYLE CONSTANTS
  const dataStyles = {
    width: '90%',
    verticalAlign: 'top',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 0,
    marginBottom: 20
  };
  
  const fontSize = 14
  const smallFontSize = 12

  const bodyStyles = {
    fontSize:{fontSize},
    padding: 0,
    height: '140%',
    marginLeft:-20, marginRight:-20, marginTop:-20, marginBottom:-20
  };

  const textareaStyle = {
    marginBottom:10, 
    fontWeight: 'bold'
    // width:1122
  }

  
  const notifySuccessStyle = {
    backgroundColor:'#C2FFB3',
    width: 600
  }
  const notifyFailStyle = {
      backgroundColor:'#FF7171',
      width: 600
  }

  // ITEMSTYLES format good on 4K at 60% zoom
  const itemStyle4K = {
    headerLogo: {color:'darkgray', fontSize:28},
    headerTop: {color:'steelblue', fontSize:24},
    headerBottom: {color:'darkgreen', fontSize:20},
    headerHighlight: {color:'red', fontSize:14, fontWeight:'bold'},
    headerDetail: {color:'black', marginLeft:10, fontSize:18},
    label: {color: 'gray', fontSize:12, fontWeight:'normal', verticalAlign:"bottom", marginLeft:0},
    basic: {fontWeight: 'bold', fontSize:14, verticalAlign:"top", marginLeft:0 },
    basicLight: {fontWeight: 'normal', fontSize:14, verticalAlign:"top", marginLeft:0 },
    upperRow: {fontWeight: 'bold', fontSize:14, verticalAlign:"bottom", marginLeft:0 },
    upperRowLight: {fontWeight: 'normal', fontSize:14, verticalAlign:"bottom", marginLeft:0 },
    smallBasicLight: {fontWeight: 'normal', fontSize:12, verticalAlign:"top", marginLeft:0 },
    list: {fontSize: 16, verticalAlign:"top", marginLeft:2 },
    search: {fontSize: 18, marginTop: 6 },
    link: {cursor:'pointer', textDecoration: 'underline'},
    bold: {fontWeight: 'bold'},
    left: {float:'left'},
    right: {float:'right'},
    phone: {textDecoration: 'underline', cursor:'pointer'},
    phoneLight: {fontSize:12, textDecoration: 'underline', cursor:'pointer'},
    underline: {textDecoration: 'underline'},
    gray: {color: 'gray'},
    black: {color: 'black'},
    red: {color: 'red'},
    maroon: {color: 'maroon'},
    blue: {color: 'blue'},
    steelblue: {color: 'steelblue'},
    amber: {color: 'brown'},
    green: {color: 'green'}
  };

  const itemStyle = itemStyle4K;

  const tileStyle = {
    color:'darkgray'
  };

  const tileStyleLink = {
    color:'darkgray',
    textDecoration: 'underline'
  };


  //=======================================================================
  const searchBox = () => {
  //=======================================================================
    return (
      <div style={{fontSize:18}}>
        <Input style={inputFields} placeholder="Search ... [ClaimID]"
                  onInput={e => resetSearchString(e.target.value)}
                  allowClear
                  style={{ width: 160, marginRight:10 }} />
      </div>
    )
  };

  const resetSearchString = (searchString) => {
    //Do this when length of string is 6 starting with "1"
    console.log("searchString", searchString)
    if ((searchString.length === 6) && !isNaN(searchString) && (searchString.substring(0, 1) == "1")) {
      console.log("claimID fulfilled", searchString)
      setClaimID(searchString)
      localStorage.setItem('claimId', (searchString))
      _getClaim()
  }
}


//=======================================================================
  //  claimIconsList
  //=======================================================================
  const iconStyle = {
    color: 'steelblue', 
    fontSize: 20, 
    width: 24
  }

  const claimIconsList = () => {
    return (
      <div style={{display:'inline-block', float:'right'}}>
        <span style={{cursor:'pointer', width:40}} >
          <Tooltip title="System Help">
            <InfoOutlined onClick={() => { alert("Help happens here"); }} style={iconStyle} /></Tooltip>
        </span>
        <span style={{cursor:'pointer', width:40}}>
          <Tooltip title="Sign-out">
            <PoweroffOutlined onClick={() => signOut() } style={iconStyle} /><br/>
          </Tooltip>
        </span>
      </div> 
    )
  }


  //=======================================================================
  //  showLoggedIn
  //=======================================================================
  const showLoggedIn = () => {
    return (
      <div style={{color:'black', marginLeft:10, fontSize:16}}>{userName} is logged in&nbsp;</div>
    )
  }


  //=======================================================================
  //  claimHeader
  //=======================================================================
  const logoImgFile = "https://nstruc.s3.ap-southeast-2.amazonaws.com/logoDCM2.png"
  const logoImgStyle = { width: '200px', height: '60px' };

  const claimHeader = () => {
    var version = 'DCM Connect ' + process.env.REACT_APP_VERSION
    if (!isLoading) {
      let userId = localStorage.getItem('userEmail')
      return (
        <div style={{fontSize:18}}>
          <table style={{verticalAlign: 'top', width: '100%'}} border={0}>
            <thead>
              <tr style={{verticalAlign:'top'}}>
                <td style={{width:'25%', textAlign: 'left'}}>
                  <Link to='/' state={{referer:"ClaimHome"}}>
                    <Tooltip title={version}>
                      <Image
                        alt='David Corey medical'
                        preview={false}
                        src={logoImgFile}
                        className='logoImgStyle'
                      />
                    </Tooltip>
                  </Link>
                </td>
                <td style={{width:'50%', textAlign: 'center'}}>
                  <span style={itemStyle.headerTop}>{claimHdrData[0].ClaimRef}</span><br/>
                  <span style={itemStyle.headerBottom}>{claimHdrData[0].IWfullname}, {claimHdrData[0].IWAddress}</span>  
                </td>
                <td style={{width:'25%', textAlign: 'right', marginRight:10, fontSize:18}}>
                  {claimIconsList()}
                  {searchBox()}
                  {showLoggedIn()}
                </td>
              </tr>
            </thead>
          </table>
        </div>
      )
    }
  };


  //=======================================================================
  function signOut() {
    //=======================================================================
      // localStorage.removeItem('userEmail')
      console.log("SignOut Fired From ClaimHome")
      localStorage.clear()
      localStorage.clear()
      Auth.signOut()
        .catch(err => console.log('error signing out: ', err))
      navigate("/")
    }

      
  //=======================================================================
  //  renderClaimValue
  //=======================================================================
  const renderClaimValue = ( records, field ) => {
    if(field == "riskAddress") {
      return (
        <div>
            {records.map((line, i) => (
              <div key={i}>
                 {line.RiskAddress}
              </div>
              ))}
        </div>
      )
    }
    else if(field == "thisStep") {
      return (
        <div>
            {records.map((line, i) => (
              <div key={i}>
                 {line.ThisStepDescr}
              </div>
              ))}
        </div>
      )
    }
    else {
      return ( <div></div> )
    }
  };


//=======================================================================
  //  renderClaimDetails
  //=======================================================================
  const renderClaimDetails = ( records ) => {
    if (!isLoading) {
      // console.log(records)
      return (
        <div style={dataStyles}>
          {records.map((line, i) => (
            <div key={i}>
              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'60%'}}>
                      <span style={itemStyle.label}>Carrier</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.maroon)}>{line.ProdShortName}</span><br/>
                    </td>
                    <td style={{width:'40%'}}>
                      <span style={itemStyle.label}>Claim Number</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.ClaimNo}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'65%'}}>
                      <span style={itemStyle.label}>Injured Worker</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.blue)}>{line.IWfullname}</span><br/>
                    </td>
                    <td style={{width:'35%'}}>
                      <span style={itemStyle.label}>Date of Birth / SSN</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.IW_DOB}</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.black)}>{line.IW_SSN}</span>
                    </td>
                  </tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'50%'}}>
                      <span style={itemStyle.label}>CONTACTS</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.phone)}>H:{line.IW_PhoneH}</span><br/>
                    </td>
                    <td style={{width:'50%'}}>
                      <span style={itemStyle.label}></span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.phone)}>M:{line.IW_Cell}</span><br/>
                    </td>
                  </tr>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2}>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue)}>{line.IW_email}</span>
                    </td>
                  </tr>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'100%'}} colSpan={4}>
                      <span style={itemStyle.label}><br/>Preferred Communication</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.maroon)}>{line.CommunicPrefDescr}</span>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td style={{width:'33%'}}>
                      <span style={itemStyle.label}>Injury</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.InjuryDate}</span>
                    </td>
                    <td style={{width:'33%'}}>
                      <span style={itemStyle.label}>Discharge</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.DischargeDate}</span>
                    </td>
                    <td style={{width:'33%'}}>
                      <span style={itemStyle.label}>Notified</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.AdvisedDate}</span>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                      <span style={itemStyle.label}>Other Notes relating to the Injured Worker</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue)}>{line.IW_Comments}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>
              </div>
            ))}
        </div>
      )
    }
  };
   

  //=======================================================================
  //  renderClaimMedicalDetails
  //=======================================================================
  const renderClaimMedicalDetails = ( records ) => {
    if(!isLoading) {
      // console.log(records)
      return (
        <div style={dataStyles}>
          {records.map((line, i) => (
            <div key={i}>
              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                        <span style={itemStyle.label}>ICD Code</span><br/>
                        <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.bold)}>{line.IW_ICDCode}</span><br/>
                    </td>
                  </tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                        <span style={itemStyle.label}>Diagnosis</span><br/>
                        <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.bold)}>{line.MED_Diagnosis}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                      <span style={itemStyle.label}>Prescriptions</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.bold)}>{line.MED_Rx}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                      <span style={itemStyle.label}>Doctors' Orders</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.bold)}>{line.MED_DrOrders}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                      <span style={itemStyle.label}>Other Medical Notes</span><br/>
                      <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.bold)}>{line.MED_Other}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              <table style={{verticalAlign: 'top', width: '100%', textAlign:"left"}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'top'}}>
                    <td colSpan={2} style={{width:'100%'}}>
                        <span style={itemStyle.label}>Discharge Notes</span><br/>
                        <span style={Object.assign({}, itemStyle.basic, itemStyle.steelblue, itemStyle.bold)}>{line.DCH_Comments}</span><br/>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr><td colSpan={2}>&nbsp;</td></tr>
                </thead>
              </table>

              </div>
            ))}
        </div>
      )
    }
  };
   

  //=======================================================================
  //  EditClaimForm
  //=======================================================================
  const EditClaimForm = ({ visible, onCreate, onCancel }) => {
    // console.log(currEditClaim);
    const [form] = Form.useForm();
    return (
      <Modal
        onCreate={console.log("onCreate")}
        width={1200}
        open={isEditClaimFormVisible}
        title="Edit Claim Details"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditClaimFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditClaimDataItem(values)
              setEditClaimFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          initialValues={currEditClaim}
          form={form} name="EditClaim_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          // initialValues={currEditClaimData}
        >
          <Form.Item name='ProductID' label='Carrier' style={{fontWeight:'bold'}}>{renderClientProducts(clientProductsData)}</Form.Item>

          <Form.Item label = "Carrier Claim Number" name="ClaimNo" style={{marginBottom:0, fontWeight:'bold'}}>
            <Input style={inputFields} placeholder="Carrier Claim Number" />
          </Form.Item>
          <br/>

          <Form.Item label="Injured Worker" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IW_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IW_Given" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IW_Middle" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}><Input style={inputFields} placeholder="Middle name/initials" /></Form.Item>
            <Form.Item name="IW_Surname" style={{ display: 'inline-block', width: 'calc(30%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Input.Group compact>
              <Tooltip title="Phone (Home)">
                <Form.Item name="IW_PhoneH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
                  <Input style={inputFields} placeholder="Phone (Home)" /> 
                </Form.Item>
              </Tooltip>
              <Tooltip title="Cell">
                <Form.Item name="IW_Cell" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
                  <Input style={inputFields} placeholder="Cell" /> 
                </Form.Item>
              </Tooltip>
              <Form.Item name="IW_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="email" /> 
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item label="Personal Details" style={{marginBottom:0}}>
            <Input.Group compact>
              <Form.Item name="IW_DOBDT" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="Date of Birth" />
              </Form.Item>
              <Tooltip title="Gender">
                <Form.Item name="IW_Gender" placeholder="Gender" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }} >
                  {renderSysCodes("Gender", "Gender")}
                </Form.Item>
              </Tooltip>
              <Tooltip title="Height">
                <Form.Item name="IW_Height" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }} > 
                    <Input style={inputFields} placeholder="Height"/>
                </Form.Item>
              </Tooltip>
              <Tooltip title="Weight">
                <Form.Item name="IW_Weight" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }} > 
                  <Input style={inputFields} placeholder="Weight"/> 
                </Form.Item>
              </Tooltip>
              <Tooltip title="SSN">
                <Form.Item name="IW_SSN" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }} > 
                  <Input style={inputFields} placeholder="SSN"/> 
                </Form.Item>
              </Tooltip>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Preferences" style={{marginBottom:0}}>
            <Input.Group compact>
            <Tooltip title="Preferred name">
              <Form.Item name="IW_Preferred" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="Preferred name" /> 
              </Form.Item>
            </Tooltip>
            <Tooltip title="Preferred language">
              <Form.Item name="IW_PrefLang" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="Preferred language"/>
              </Form.Item>
            </Tooltip>
            </Input.Group>
          </Form.Item>

          <Form.Item label="Home Address" style={{marginBottom:0}}>
            <Form.Item name="IW_Address1" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 1"/></Form.Item>
            <Form.Item name="IW_Address2" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 2"/></Form.Item>
              <Input.Group compact>
                <Form.Item name="IW_City" style={{ display: 'inline-block', width: 'calc(50%)' }} rules={[ { required: true } ]} >
                  <Input style={inputFields} placeholder="City/Town" /> 
                </Form.Item>
                <Form.Item name="IW_State" style={{ display: 'inline-block', width: 'calc(25%)' }} >
                  <Input style={inputFields} placeholder="State"/>
                </Form.Item>
                <Form.Item name="IW_Zipcode" style={{ display: 'inline-block', width: 'calc(25%)' }} > 
                  <Input style={inputFields} placeholder="Zipcode"/> 
                </Form.Item>
              </Input.Group>
          </Form.Item>
          <Form.Item name="IW_Jurisdiction" label="Jurisdiction State"><Input style={inputFields} placeholder="Jurisdiction State"/></Form.Item>

          <Form.Item label="Injured Worker's Contact Person" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IWC_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IWC_Given" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IWC_Middle" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }}><Input style={inputFields} placeholder="Middle name/initials" /></Form.Item>
            <Form.Item name="IWC_Surname" style={{ display: 'inline-block', width: 'calc(30%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Input.Group compact>
              <Tooltip title="Phone (Home/Work)">
                <Form.Item name="IWC_PhoneH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
                  <Input style={inputFields} placeholder="Phone (Home/Work)" /> 
                </Form.Item>
              </Tooltip>
              <Tooltip title="Cell">
                <Form.Item name="IWC_Cell" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
                  <Input style={inputFields} placeholder="Cell" /> 
                </Form.Item>
              </Tooltip>
              <Form.Item name="IWC_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="email" /> 
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label = "Relationship" name='IWC_Relship'>
            <Input style={{ display: 'inline-block', width: 'calc(100%)' }}/>
          </Form.Item>

          <Form.Item label="Injury/Discharge Dates" style={{marginBottom:0, fontWeight:'bold'}}>
            <Input.Group compact>
              <Tooltip title="Date of Injury">
                <Form.Item name='MED_InjuryDT' style={{ marginBottom:0}}>
                  <Input style={inputFields} placeholder="Date of Injury" />
                </Form.Item>
              </Tooltip>
              <Tooltip title="Date of Surgery">
                <Form.Item name='SurgeryDT' style={{ display: 'inline-block', width: 'calc(33%)'}}>
                  <Input style={inputFields} placeholder="Date of Surgery" /> 
                </Form.Item>
              </Tooltip>
              <Tooltip title="Date of Discharge">
                <Form.Item name='DCH_DischargeDT' style={{ marginBottom:0}}>
                  <Input style={inputFields} placeholder="Date of Discharge" /> 
                </Form.Item>
              </Tooltip>
            </Input.Group>
          </Form.Item>
          <br/>

          <Form.Item name="IW_HospitalName" label="Hospital" style={{ marginBottom:0, fontWeight:'bold' }} >
            <Input style={inputFields} placeholder="Hospital" /> 
          </Form.Item>
          <Form.Item name="IW_HospitalRoomNo" label="Ward/Room No" style={{ marginBottom:0 }} >
            <Input style={inputFields} placeholder="Ward/Room No" /> 
          </Form.Item>

          <Form.Item label="Status">
            <Form.Item name="IW_DischargeCode" placeholder="Discharge Status" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              {renderSysCodes("DischargeStatus", "Discharge status?")}
            </Form.Item>
          </Form.Item>

          <Form.Item label = "ICD Code" name="IW_ICDCode" style={{marginBottom:0, fontWeight:'bold'}}>
            <Input style={inputFields} placeholder="ICD Code" />
          </Form.Item>
          <br/>
          <Form.Item label = "Diagnosis details" name='MED_Diagnosis' style={textareaStyle}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item label="Prescription details" name='MED_Rx' style={textareaStyle}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item label = "Doctors' orders" name='MED_DrOrders' style={textareaStyle}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item label = "Other notes relating to the diagnosis" name='MED_Other' style={textareaStyle}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item label = "Discharge notes" name='DCH_Comments' style={textareaStyle}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item label = "Other notes relating to the Injured Worker" name='IW_Comments' style={textareaStyle}>
            <TextArea style={inputFields}   rows={2}/>
          </Form.Item>
        </Form>

      </Modal>
    );
  };

  const onCreateEditClaimForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditClaimDataItem
  //=======================================================================
  const _setEditClaimDataItem = async (formData) => {
    let currAction = "Update";

    setIsLoading(false)

    var productID = formData.ProductID.toString()
    if(!productID) productID = ""

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setClaim", {
      body: JSON.stringify(
        {
          "loginId": '10001',
          "claimID": claimHdrData[0].ClaimID.toString(),
          "productID": productID.toString(),
          "claimNo":formData.ClaimNo,

          "iW_Title":formData.IW_Title,
          "iW_Given":formData.IW_Given,
          "iW_Middle":formData.IW_Middle,
          "iW_Surname":formData.IW_Surname,
          "iW_PhoneH":formData.IW_PhoneH,
          "iW_Cell":formData.IW_Cell,
          "iW_email":formData.IW_email,

          "iW_DOBDT":formData.IW_DOBDT,
          "iW_Gender":formData.IW_Gender,
          "iW_Height":formData.IW_Height,
          "iW_Weight":formData.IW_Weight,
          "iW_SSN":formData.IW_SSN,

          "iW_Preferred":formData.IW_Preferred,
          "iW_PrefLang":formData.IW_PrefLang,

          "iW_Address1":formData.IW_Address1,
          "iW_Address2":formData.IW_Address2,
          "iW_City":formData.IW_City,
          "iW_State":formData.IW_State,
          "iW_Zipcode":formData.IW_Zipcode,
          "iW_Jurisdiction":formData.IW_Jurisdiction,

          "iWC_Title":formData.IWC_Title,
          "iWC_Given":formData.IWC_Given,
          "iWC_Middle":formData.IWC_Middle,
          "iWC_Surname":formData.IWC_Surname,
          "iWC_PhoneH":formData.IWC_PhoneH,
          "iWC_Cell":formData.IWC_Cell,
          "iWC_email":formData.IWC_email,
          "iWC_Relship":formData.IWC_Relship,

          "mED_InjuryDT":formData.MED_InjuryDT,
          "surgeryDT":formData.SurgeryDT,
          "dCH_DischargeDT":formData.DCH_DischargeDT,

          "iw_HospitalName":formData.IW_HospitalName,
          "iw_HospitalRoomNo":formData.IW_HospitalRoomNo,
          "iw_DischargeCode":formData.IW_DischargeCode,
          "iw_RushStatCode":"",

          "iW_ICDCode":formData.IW_ICDCode,
          "mED_Diagnosis":formData.MED_Diagnosis,
          "mED_Rx":formData.MED_Rx,
          "mED_DrOrders":formData.MED_DrOrders,
          "mED_Other":formData.MED_Other,

          "dCH_Comments":formData.DCH_Comments,
          "iW_Comments":formData.IW_Comments
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("New Claim API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new claim data");
    // props.history.push('claimhome')
  }


//=======================================================================
//  _getClaimRoleData
//=======================================================================
const _getClaimRoleData = async (claimRoleID) => {
  console.log(claimRoleID)
    await fetch(process.env.REACT_APP_BASE_URL + "getClaimRole", {
      body: JSON.stringify(
        {
          "action": '',
          "loginID": '10001',
          "claimRoleID": claimRoleID.toString(),
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => setCurrClaimRole(responseJSON.recordset[0]))
    .finally(setClaimRoleFormVisible(true))
  }
  
  
  //=======================================================================
  //  renderClaimRoles
  //=======================================================================
  const renderClaimRoles = ( records ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          {records.map((line, i) => (
            <div key={i}>
            <table>
              <thead>
                <tr>
                    <td>
                      <span style={Object.assign({}, itemStyle.label, itemStyle.darkgray)}>{line.RoleTypeStr} </span><br/>
                      <Tooltip title={line.Notes}>
                        <span 
                          onClick={() => { 
                            setCurrEditClaimRole({
                              modifier: 'public',
                              ClaimRoleID: line.ClaimRoleID,
                              crType: line.crType,
                              crPartnerID: line.crPartnerID,
                              crPersonID: line.crPersonID,
                              crNotes: line.crNotes
                            });
                            console.log(currEditClaimRole);
                            _getClaimRoleData(line.ClaimRoleID)
                            // setClaimRoleFormVisible(true); 
                            }}
                          >
                            <EditOutlined style={{cursor:'pointer', fontSize:18, width:{iconWidth}}} /> 
                            <span style={Object.assign({}, itemStyle.basicLight, itemStyle.blue)}>{line.Person}</span>&nbsp;&nbsp;
                            <span style={Object.assign({}, itemStyle.basicLight, itemStyle.blue, itemStyle.phone)}>{line.Mobile}</span>&nbsp;&nbsp;

                            <span style={Object.assign({}, itemStyle.basicLight, itemStyle.black)}>{line.Company}</span>
                        </span>
                        &nbsp;&nbsp;
                        <span style={Object.assign({}, itemStyle.basicLight, itemStyle.green, itemStyle.phone)}>{line.Phones} </span>
                      </Tooltip>
                    </td>
                </tr>
              </thead>
            </table>
            </div>
            )
          )}
      </div>
      )
    }
  };


 


  //=======================================================================
  //  ClaimRoleForm
  //=======================================================================
  const ClaimRoleForm = ({ visible, onCreate, onCancel }) => {
    const [partnersData, setPartnersData] = useState(globPartnersData)
    const [partnerPersonsData, setPartnerPersonsData] = useState(globPartnerPersonsData)

    const [roleType, setRoleType] = useState('')

    console.log(currEditClaimRole.ClaimRoleID)

    //  CLAIMROLE PARTNERS FOR GIVEN ROLETYPE
    const setClaimRolePartnersList = async ( newRoleType ) => {
      console.log(newRoleType)

      var UserEmail = localStorage.getItem("userEmail")

      await fetch(process.env.REACT_APP_BASE_URL + "listPartners", {
        body: JSON.stringify(
          {
            "action": 'RolePartners',
            "loginID": UserEmail,
            "partnerType": newRoleType,
            "search":""
          }
        ),
             headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
        method: "POST"
      })
      .then((response) => response.json())
      .then((responseJSON) => setPartnersData(responseJSON[0]))
      .then((data) => console.log("NEWROLEDATA",data))

      return( renderPartnerNames(partnersData) )
    }

    const renderPartnerNames = ( records ) => {
      console.log(records) 
      return (
        <Select showSearch placeholder={"Select the Organization Type ..."} >
          {records.map((line, i) => <Option key={i} value={line.ShortName}>{line.ShortName}</Option>)}
        </Select>
      )
    };


    //=======================================================================
    const setClaimRolesPersonsList = async ( newPartnerID, roleType ) => {
    //=======================================================================
      console.log(newPartnerID, roleType)

      if(!roleType) roleType = ""

      var UserEmail = localStorage.getItem("userEmail")
      var claimId = localStorage.getItem("claimId")

      await fetch(process.env.REACT_APP_BASE_URL + "listPersons", {
        body: JSON.stringify(
          {
            "action": '',
            "loginID": UserEmail,
            "partnerID": newPartnerID.toString(),
            "claimID": claimId,
            "roleType": roleType,
            "search": '',
          }
        ),
             headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
        method: "POST"
      })
      .then((response) => response.json())
      .then((responseJSON) => setPartnerPersonsData(responseJSON[0]) )

      return( renderPartnerPersons(partnerPersonsData) )
    }


    //=======================================================================
    const resetClaimRoleSelectLists = (newRoleType, newPartnerID, newPersonID) => {
    //=======================================================================
    console.log( roleType )

    // let roleType
      let claimRoleID
      let partnerID
      let personID
  
      if(currClaimRole) {
        // roleType = currClaimRole.RoleType
        setRoleType(currClaimRole.RoleType)

        claimRoleID = currClaimRole.ClaimRoleID
        partnerID = currClaimRole.PartnerID
        personID = currClaimRole.PersonID
      }
  
      console.log( newRoleType, newPartnerID, newPersonID, claimRoleID, partnerID, personID, roleType )
  
      if( newRoleType) { 
        console.log("newRoleType: ", newRoleType)
        
        setRoleType(newRoleType)
        console.log( newRoleType, roleType )

        setClaimRolePartnersList( newRoleType )
      }
      if( newPartnerID) { 
        console.log("newPartnerID: ", newPartnerID, "ROLETYPE", roleType)
        setClaimRolesPersonsList( newPartnerID, roleType )
      }
      if( newPersonID) {
         console.log( "newPersonID", newPersonID )
      }

      console.log( roleType )
    };


    //=======================================================================
    //  ClaimRole Form
    const [form] = Form.useForm();
    //=======================================================================
    return (
      <Modal
        onCreate={console.log("ClaimRole Form Created")}
        width={800}
        visible={isClaimRoleFormVisible}
        title="Add or Edit Claim Role Details"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setClaimRoleFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setClaimRoleDataItem(values)
              setClaimRoleFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} 
          name="ClaimRole_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          initialValues={currClaimRole}
          onValuesChange={(values) => { resetClaimRoleSelectLists( values.RoleType, values.PartnerID, values.PersonID ) }
          }
        >
          <Form.Item name='ClaimRoleID' style={{marginBottom:20}}
                      label={<label style={{color:'blue', fontWeight:'normal'}}>ClaimRoleID</label>} >
              <Input readOnly  style={{color:'blue', fontWeight:'bold'}} />
          </Form.Item>

          <Form.Item name='RoleType' label='Claim Role Type' style={{fontWeight:'normal'}}>
            {renderSysCodes("RoleType", "Select the Role Type")}
          </Form.Item>

          <Form.Item name='PartnerID' label='Organization' style={{marginBottom:20, fontWeight:'normal'}}>
            {renderPartnerNames(partnersData)}
          </Form.Item>

          <Form.Item name='PersonID' label='Person' style={{marginBottom:20, fontWeight:'normal'}}>
            {renderPartnerPersons(partnerPersonsData)}
          </Form.Item>

          <Form.Item name="Notes" label='Notes' style={{marginBottom:20, fontWeight:'normal'}}>
            <TextArea style={inputFields}  rows={2}/>
          </Form.Item>

          <Form.Item name='IsDeleted' valuePropName="checked" 
                        label={<label style={{color:'red'}}>DELETE THIS CLAIM ROLE RECORD?</label>}
                        style={{fontWeight:'bold', color:'red', marginTop:40}} >
            <Checkbox />
          </Form.Item>

        </Form>
      </Modal>
    );
  };

  const onCreateClaimRoleForm = (values, title) => {
    setIsLoading(true)
  };

  
  //=======================================================================
  //  _setClaimRoleDataItem
  //=======================================================================
  const _setClaimRoleDataItem = async (formData) => {
    let currAction = "Add";

    setIsLoading(false)

    console.log(currEditClaimRole.ClaimRoleID)

    var claimRoleID = currEditClaimRole.ClaimRoleID
    if(!claimRoleID) { claimRoleID = "" }
    console.log( claimRoleID )

    var personID = ""
    if(formData.PersonID) personID = formData.PersonID

    var notes = ""
    if(formData.Notes) notes = formData.Notes

    var isDel = ""
    if(formData.IsDeleted) isDel = formData.IsDeleted

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setClaimRole", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": '10001',
          "claimRoleID": claimRoleID.toString(),
          "claimID": claimHdrData[0].ClaimID.toString(),

          "crType": formData.RoleType,
          "crPartnerID": formData.PartnerID.toString(),
          "crPersonID": personID.toString(),
          "crNotes": notes,

          "ppTitle": "",
          "ppFirstName": "",
          "ppLastName": "",
          "ppJobTitle": "",
          "ppPhoneW": "",
          "ppMobile": "",
          "ppEmail": "",

          "isDeleted": isDel.toString()
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Claim Role API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Claim Role data");
  }

 
  //=======================================================================
  //  renderSteps
  //=======================================================================
  const { Step } = Steps;

  const showStep = ( stepDescr, stepEndDT ) => {
    return (
      <div>
        <span 
          onClick={() => { setStep(stepDescr); setEditStepFormVisible(true); }}
          style={Object.assign({}, itemStyle.basicLight, itemStyle.blue, itemStyle.link)} >
            {stepDescr}
        </span>
        &nbsp;
        <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{stepEndDT}</span>
    </div>
    )
  };
  
  const renderSteps = ( records ) => {
    if (!isLoading) {
      let currStep = 1
      if(claimHdrData.length === 0) {
        currStep = 1
      }
      else {currStep = claimHdrData[0].ThisStepNo}
      
      return (
        <div>
          <Steps direction="vertical" current={currStep} style={dataStyles}>
            {records.map((line, i) => (
              <Step 
                key={i}
                title={<span style={Object.assign({}, itemStyle.list, itemStyle.gray)}>{line.StepStatus}</span>}
                description={showStep(line.ThisStep, line.StepEndsStr)}
                onClick= {() => setCurrStep(i)}
                />
            )
          )}
          </Steps>
        </div>
      )
    }
  };
  
  
  //=======================================================================
  //  EditStepForm
  //=======================================================================
  const EditStepForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    const formTitle = "Editing Step: " + step;
    // console.log(formTitle);

    const format = 'yyyy-MM-DD HH:mm';

    return (
      <Modal
        onCreate={console.log("Edit Step Form Created")}
        width={800}
        visible={isEditStepFormVisible}
        title={formTitle}
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditStepFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditStepDataItem(values)
              setEditStepFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} name="EditStep_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          initialValues={stepsData[currStep]}
        >
          {/* <Form.Item name='AboutCRID' label='Step relates to' style={{marginBottom:20}}>{renderClaimRolesDropdownList(claimrolesData)}</Form.Item> */}
          
          {/* <Form.Item label="STEP IS NOT YET COMPLETE" style={{marginTop:20, marginBottom:0}}>
            <TextArea style={inputFields}  name='NotCompleteReason' placeholder='Reason not yet complete' rows={4} style={{marginBottom:20}}/>
            <Form.Item name="NextStepDueDT" label="Expected Date Step expected to be complete"><DatePicker /></Form.Item>
          </Form.Item> */}

          <Form.Item name='StepNotes' label="STEP IS NOW COMPLETE" style={{marginTop:20, marginBottom:0}}>
            <TextArea style={inputFields}  placeholder='Notes relating to the Step completion' rows={4} style={{marginBottom:20}}/>
          </Form.Item>

          <Tooltip title="Default is current date/time">
            <Form.Item name="StepCompleteDT" label="Date/Time Step was completed"><DatePicker showTime format={format}/></Form.Item>
          </Tooltip>

          {/* <Form.Item name="NextStepDueDT" label="Date Next Step due by"><DatePicker /></Form.Item> */}

          <Tooltip title="Use this date/time to record upcoming event e.g. Appointment for Visit">
            <Form.Item name="StepEffDT" label="Date/Time Action Due (if applicable)"><DatePicker showTime format={format}/></Form.Item>
          </Tooltip>

          <Form.Item name='StepNotApplicable' valuePropName="checked" label="STEP IS NOT APPLICABLE?" style={{marginBottom:20}} ><Checkbox /></Form.Item>

          <Form.Item name='StepExcludeFromStats' valuePropName="checked" label="EXCLUDE STEP FROM SLA's?" style={{marginBottom:0}} ><Checkbox /></Form.Item>

          <Form.Item name='StepExcludeNotes'label="Exclusion Reasons" style={{marginBottom:0}} >
            <TextArea style={inputFields}   placeholder='Reason(s) this Step is to be excluded from SLA measures' rows={4} style={{marginBottom:20}}/>
          </Form.Item>

        </Form>
      </Modal>
    );
  };

  const onCreateEditStepForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditStepDataItem
  //=======================================================================
  const _setEditStepDataItem = async (formData) => {
    let currAction = "SetStepAction";

    let stepNA = "false"
    if(formData.StepNotApplicable) stepNA = "true"
    console.log(formData.StepNotApplicable, stepNA)

    let stepExcl = "false"
    if(formData.StepExcludeFromStats) stepExcl = "true"
    console.log(formData.StepExcludeFromStats, stepExcl)

    setIsLoading(false)

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setStepAction", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": '10001',
          "claimID": claimHdrData[0].ClaimID.toString(),

          "stepActionType": step,
          
          "completeDate": formData.StepCompleteDT,
          "effDate": formData.StepEffDT,
          
          "notes": formData.StepNotes,

          "notApplicable": stepNA,
          
          "excludeFromStats": stepExcl,
          "excludeCode": '',
          "excludeNotes": formData.StepExcludeNotes
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Claim Role API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Step data");
  }

  

  //=======================================================================
  //  renderComplaints
  //=======================================================================
  // const renderComplaints = ( records ) => {
  //   return (
  //     <div style={dataStyles}>
  //         {records.map((line, i) => (
  //           <div key={i}>
  //             <table style={{width:'100%'}} border={0}>
  // <thead>
  //   <tr style={{width:'100%'}}>
  //     <td style={{width:'40%'}}>
  //       <span style={itemStyle.label}>Notified</span><br/>
  //       <span style={Object.assign({}, itemStyle.basic, itemStyle.green)}>{line.NotifiedDate}</span>
  //     </td>
  //     <td style={{width:'40%'}}>
  //       <span style={itemStyle.label}>ComplaintID</span><br/>
  //       <span style={Object.assign({}, itemStyle.basic, itemStyle.blue)}>CM{line.ComplaintID}</span><br/>
  //     </td>
  //     <td style={{width:'20%'}}>
  //       &nbsp;
  //     </td>
  //   </tr>
  // </thead>
  // <thead>
  //   <tr><td colSpan={2}>&nbsp;</td></tr>
  // </thead>
  // //             </table>

  // //             <table style={{width: '100%'}} border={0}>
  // <thead>
  //   <tr>
  //     <td>
  //       <span style={itemStyle.label}>Details</span><br/>
  //       <span style={Object.assign({}, itemStyle.list, itemStyle.red)}>{line.ComplaintNotes}</span><br/>
  //     </td>
  //   </tr>
  // </thead>
  // <thead>
  //   <tr><td colSpan={2}>&nbsp;</td></tr>
  // </thead>
  // //             </table>

  // //             <table style={{width: '100%'}} border={0}>
  // <thead>
  //   <tr>
  //     <td>
  //       <span style={itemStyle.label}>Resolution</span><br/>
  //       <span style={Object.assign({}, itemStyle.list, itemStyle.green)}>{line.ResolutionNotes}</span><br/>
  //     </td>
  //   </tr>
  // </thead>
  // <thead>
  //   <tr><td colSpan={2}>&nbsp;</td></tr>
  // </thead>
  //             </table>
  //           </div>
  //         ))}
  //     </div>
  //   )
  // };

  //=======================================================================
  //  Split and format the ServiceItems data to show in the Service Referrals table
  //=======================================================================
    const renderServiceLine = ( serviceLineStr ) => {
      // console.log(serviceLineStr)
      var items = serviceLineStr.split("~")
      
      return (
        <div style={{verticalAlign:'top', fontWeight:'normal'}}>
          <span style={{marginLeft:10, fontSize:12, color:'green', textAlign:'left'}}>{items[2]}</span>&nbsp;&nbsp;
          <span style={{fontSize:12, color:'darkgray', textAlign:'left'}}>{items[1]}</span>&nbsp;&nbsp;
          <span style={{fontSize:12, color:'steelblue', textAlign:'left'}}>{items[0]}</span>
        </div>
      )
    }

  const renderServiceItems = ( serviceItemsStr ) => {
    // console.log(serviceItemsStr)

    if(!serviceItemsStr) serviceItemsStr = ""
      var lines = serviceItemsStr.split("|")
      // console.log(lines)
  
      return (
        <div style={{verticalAlign:'top', fontWeight:'normal'}}>
          {lines.map((line, i) => (
            <div key={i}>
              {renderServiceLine(line)}
            </div>
            )
          )}
        </div>      
      )
    };


  // =======================================================================
  //  renderServiceCosts
  // =======================================================================
  const renderServiceCosts = ( records ) => {
    // console.log(records)
    return (
      <div style={dataStyles}>
          <table style={{width:'100%'}} border={0}>
            <thead>
              <tr style={{width:'100%'}}>
                <td style={{width:'20%', textAlign:'left'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Type</span><br/>
                </td>
                <td style={{width:'16%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Date</span><br/>
                </td>
                <td style={{width:'20%', textAlign:'center'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Amount</span><br/>
                </td>
                <td style={{width:'40%'}}>
                  <span style={Object.assign({}, itemStyle.label, itemStyle.underline)}>Provider</span><br/>
                </td>
              </tr>
            </thead>
          </table>

          {records.map((line, i) => (
            <div key={i}>
              <table style={{marginTop:10, width:'100%'}} border={0}>
                <thead>
                  <tr style={{width:'100%', verticalAlign:'bottom'}}>
                    <td style={{width:'20%', textAlign:'left'}}>
                      <span style={Object.assign({}, itemStyle.upperRow, itemStyle.red)}>{line.ServiceType}</span><br/>
                    </td>
                    <td style={{width:'16%'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.green)}>{line.CostDate}</span><br/>
                    </td>
                    <td style={{width:'20%', textAlign:'center'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.red)}>{line.GrossCharges}</span><br/>
                    </td>
                    <td style={{width:'40%'}}>
                      <span style={Object.assign({}, itemStyle.upperRowLight, itemStyle.black)}>{line.ServiceProvider}</span><br/>
                    </td>
                  </tr>
                  <tr style={{verticalAlign:'top'}}>
                    <td colSpan='4'>
                      {/* {renderServiceItems(line.ServiceItems)} */}
                    </td>                    
                  </tr>
                </thead>
              </table>
            </div>
          ))}
      </div>
    )
  };


  //=======================================================================
  //  LOG FILTERS AND ICONS
  //=======================================================================
  const filterLogs = ( recType ) => {
    switch (recType) {
      case "Show the Reminders":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('Reminder')).slice(0, 20))
        break;
      
      case "Show the Logs":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('Log')).slice(0, 20))
        break;
      
      case "Show the Uploads":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('Upload')).slice(0, 20))
        break;
      
      case "Remove Log Filters":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('')).slice(0, 20))
        break;

      case "Show the emails":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('email')).slice(0, 20))
        break;

      case "Show the SMSs":
        setFilteredLogs(logData.filter(type  => type.RecType.includes('SMS')).slice(0, 20))
        break;

    }

  };

  const addRecord = ( recType ) => {
    console.log("Add a", recType);
  };

  const iconFontSize = 28;
  const iconWidth = 40;
  

  //=======================================================================
  //  ICON LINKS
  //=======================================================================
  const renderIconLink = ( iconType, iconTooltip ) => {
    switch(iconType) {
      case 'Edit': 
        return (
          <div><Tooltip title={iconTooltip}><EditOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );

      case 'UserAdd': 
        return (
          <div><Tooltip title={iconTooltip}><UserAddOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );
  
      case 'Alert': 
        return (
          <div><Tooltip title={iconTooltip}><AlertOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );
  
      case 'Edit': 
        return (
          <div><Tooltip title={iconTooltip}><EditOutlined style={{fontSize:28, width:{iconWidth}}} /></Tooltip></div>
          );

      case 'addclaimlogsbytype': 
        return ( 
          <div style={{display:'inline-block'}}>
            <span style={{cursor:'pointer', width:40}} onClick={() => { _sendEmail(); }}>
              <Tooltip title="Send an email"><MailOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
            <span style={{cursor:'pointer', width:40}} onClick={() => { _sendSMS(); }}>
              <Tooltip title="Send an SMS"><MessageOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
            <span style={{cursor:'pointer', width:40}} onClick={() => { setEditReminderFormVisible(true); }}>
              <Tooltip title="Add a Reminder"><AlertOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
            <span style={{cursor:'pointer', width:40}} onClick={() => { setEditLogFormVisible(true); }}>
              <Tooltip title="Add a Log record"><DatabaseOutlined style={{fontSize:28, width:40}} /></Tooltip>
            </span>
          </div> 
        );
    }
  };


  const goToLink = ( itemLink ) => {
    switch(itemLink) {
      case "editclaim": { setEditClaimFormVisible(true); break; }

      case "addclaimrole": { setCurrClaimRole(); setClaimRoleFormVisible(true); break; }
  
      case "addreminder": { setEditReminderFormVisible(true); break; }
  
      case "referrals": { navigate("/referrals"); break; }
      case "costslist": { navigate("/costslist"); break; }
  
      case "docslist": { navigate("/docslist"); break; }
    
      case "claimlogs": { console.log("6"); navigate("/claimlogs"); break; }
    }  
  };


  const renderFilterIconLink = ( icon, tooltip ) => {
    return(
      <div style={{float:'left', fontSize:28, cursor:'pointer'}} onClick={() => { filterLogs(tooltip); }}>
        <Tooltip title={tooltip}>{icon}</Tooltip>
        &nbsp;&nbsp;
      </div>
    )
  };


  const renderTileHeaderTitleLink = ( title, titleLink ) => {
    // console.log(title, titleLink)
    if (titleLink == "") {
      return(
        <div>
          <span>{title}</span>
        </div>
      )
    }
    else
    {
      return(
        <div>
          <span style={{color: 'blue'}}>{title}</span>
        </div>
      )
    }
  };


  //=======================================================================
  //  renderTileHeader
  //=======================================================================
  const renderTileHeader = ( title, titleLink, titleTooltip,  iconType, iconLink, iconTooltip )   => {

    if (iconLink == "") {
      return (
        <div>
          <table style={{width:'100%'}} border={0}>
            <thead>
              <tr>
                <td style={{width:'100%'}}>
                  <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(titleLink); }}>
                    <Tooltip title={titleTooltip}>
                      {renderTileHeaderTitleLink(title, titleLink)}
                    </Tooltip>
                  </span>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      )
    }
    else
    {
      if (iconLink == "addclaimlogsbytype") {
        return (
          <div>
            <table style={{width:'100%'}} border={0}>
              <thead>
                <tr>
                  <td style={{width:'60%'}}>
                    <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(titleLink); }}>
                      <Tooltip title={titleTooltip}>
                        {renderTileHeaderTitleLink(title, titleLink)}
                      </Tooltip>
                    </span>
                  </td>
                  <td style={{width:'40%'}}>
                    <div style={{display:'inline-block'}}>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { _sendEmail(); }}>
                        <Tooltip title="Send an email"><MailOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { _sendSMS(); }}>
                        <Tooltip title="Send an SMS"><MessageOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { setEditReminderFormVisible(true); }}>
                        <Tooltip title="Add a Reminder"><AlertOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                      <span style={{cursor:'pointer', width:40}} onClick={() => { setEditLogFormVisible(true); }}>
                        <Tooltip title="Add a Log record"><DatabaseOutlined style={{fontSize:28, width:40}} /></Tooltip>
                      </span>
                    </div> 
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        )
      }
      else
      { 
        return (
          <div>
            <table style={{width:'100%'}} border={0}>
              <thead>
                <tr>
                  <td style={{width:'55%'}}>
                    <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(titleLink); }}>
                      <Tooltip title={titleTooltip}>
                        {renderTileHeaderTitleLink(title, titleLink)}
                      </Tooltip>
                    </span>
                  </td>
                  <td style={{width:'45%'}}>
                    <span style={{cursor:'pointer', width:40}} onClick={() => { goToLink(iconLink); }}>
                      {renderIconLink(iconType, iconTooltip)}
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        )
      }
    }
  };


  //=======================================================================
  //  SEARCH LOGS
  //=======================================================================
  const searchBoxLogs = () => {
    // console.log(logData)
    return (
      <div style={Object.assign({}, itemStyle.search, itemStyle.left)}>
        <Input style={inputFields} placeholder="Search ..." allowClear
                  onInput={e => setFilteredLogs(logData.filter(type  => type.LogSearch.toLowerCase().includes(e.target.value.toLowerCase())))}
                  onPressEnter={() => onSearchLogs()}
                  style={{ width: 170, marginLeft:20, marginRight:4 }} />
      </div>
    )
  };

  const onSearchLogs = () => {
    console.log(searchLogs)
    setLogData(logData.filter(type  => type.LogSearch.toLowerCase().includes(searchLogs.toLowerCase())))
  };

  const showItem = ( item ) => {
    switch(item) {
      case 'thisStep': return <span>{claimHdrData.thisStep}</span>;
      case 'riskAddress': return <span>{claimHdrData.RiskAddress}</span>;
      default: return <span></span>;
    }
  };
 

  //=======================================================================
  //  renderLogs
  //=======================================================================
  const renderLogs = ( records, type, search ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          <div style={{display:'inline-block', marginBottom:20}}>
            {renderFilterIconLink(<MailOutlined/>, "Show the emails")}
            {renderFilterIconLink(<MessageOutlined/>, "Show the SMSs")}
            {renderFilterIconLink(<AlertOutlined/>, "Show the Reminders")}
            {renderFilterIconLink(<DatabaseOutlined/>, "Show the Logs")}
            {renderFilterIconLink(<UploadOutlined/>, "Show the Uploads")}
            {searchBoxLogs()}
            {renderFilterIconLink(<CloseOutlined/>, "Remove Log Filters")}
          </div>
          <div>
            {records.map((line, i) => (
              <div key={i}>
              <table>
                <thead>
                  <tr style={{height:20, verticalAlign:'top'}}>
                      <td style={{width:'100%'}}>
                        <Tooltip title={line.LogText}>
                          <span 
                            onClick={() => { 
                              console.log("clicked Log", line.RecType, line.RecID);
                              switch(line.recType) {
                                case "Log":
                                  console.log("Cannot edit a Claim Log");
                                case "Reminder":
                                  setEditReminderFormVisible(true);
                              }
                            }}
                            style={Object.assign({}, itemStyle.list, itemStyle.red, itemStyle.link)} >
                              {line.RecType}
                          </span>

                          <span style={Object.assign({}, itemStyle.list, itemStyle.maroon)}>{line.LogDateStr}</span>&nbsp;
                          <span style={Object.assign({}, itemStyle.list, itemStyle.blue)}>{line.LogTypeDescr}</span>&nbsp;
                          <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.LogShort}</span>&nbsp;
                          <span style={Object.assign({}, itemStyle.list, itemStyle.green)}>{line.LastChangedBy}, {line.RecordedDateStr}</span>
                        </Tooltip>                          
                        <br/><br/>
                      </td>
                  </tr>
                </thead>
              </table>
              </div>
              )
            )}
          </div>
      </div>
      )
    }
  };


  //=======================================================================
  //  EditLogForm
  //=======================================================================
  const EditLogForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    const formTitle = "Add a Claim Log"

    const format = 'yyyy-MM-DD HH:mm';

    return (
      <Modal
        onCreate={console.log("Edit Log Form Created")}
        width={800}
        visible={isEditLogFormVisible}
        title={formTitle}
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditLogFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditLogDataItem(values)
              setEditLogFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} name="EditLog_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          // initialValues={}
        >
          <Form.Item name='LogType' label='Log Type' style={{marginBottom:20}}>{renderSysCodes("LogType", "Log Type")}</Form.Item>

          <Form.Item name='LogRef' label='Reference' style={{marginBottom:20}}><Input /></Form.Item>

          <Form.Item name='LogText' label="Notes/text" style={{marginTop:20, marginBottom:0}}>
            <TextArea style={inputFields}  placeholder='Notes/text for the Claim Log' rows={4} style={{marginBottom:20}}/>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const onCreateEditLogForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditLogDataItem
  //=======================================================================
  const _setEditLogDataItem = async (formData) => {
    let currAction = "AddLog";
    setIsLoading(false)

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setClaimLog", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": '10001',
          "claimID": claimHdrData[0].ClaimID.toString(),
          "logType": formData.LogType,
          "logSubType": '',
          "logRef": formData.LogRef,
          "entityType": 'CL',
          "entityID": claimHdrData[0].ClaimID.toString(),
          "logDate": formData.LogDate,
          "logText": formData.LogText
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Claim Log API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Step data");
  }


  //=======================================================================
  //  renderReminders
  //=======================================================================
  const renderReminders = ( records ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          <br/>
          {records.map((line, i) => (
            <div key={i}>
            <table>
              <thead>
                <tr>
                    <td>
                      <div 
                        style={{cursor:'pointer', float:'left'}} 
                        onClick={() => { _dismissReminderDataItem(line.ReminderID); }}>
                        <Tooltip title={dismissReminderText(line.ReminderID)}>
                          <CheckCircleOutlined style={{fontSize:20, marginTop:0, marginRight:4, width:{iconWidth}}} />
                        </Tooltip>
                      </div>
                      <span style={Object.assign({}, itemStyle.list, itemStyle.red)}>{line.OverdueStatus}</span>
                      <span style={Object.assign({}, itemStyle.list, itemStyle.amber)}>{line.DueNowStatus}</span>&nbsp;
                      <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.ClaimReminderDetail}</span>&nbsp;
                      <span style={Object.assign({}, itemStyle.list, itemStyle.green)}>{line.PersonName}, {line.FromDate}</span><br/><br/>
                    </td>
                </tr>
              </thead>
            </table>
            </div>
            )
          )}
      </div>
      )
    }
  };
//_dismissReminderDataItem

  const editReminderText = ( reminderID ) => {
    return ( "Edit Reminder" + reminderID );
  };

  const editReminder = ( reminderID ) => {
    console.log( "Edit Reminder" + reminderID );
  };

  const dismissReminderText = ( reminderID ) => {
    return ( "Dismiss Reminder" + reminderID );
  };


  //=======================================================================
  //  EditReminderForm
  //=======================================================================
  const EditReminderForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    const formTitle = "Add or Edit a Reminder"

    const format = 'yyyy-MM-DD HH:mm';

    return (
      <Modal
        onCreate={console.log("Edit Reminder Form Created")}
        width={800}
        open={isEditReminderFormVisible}
        title={formTitle}
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setEditReminderFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setEditReminderDataItem(values)
              setEditReminderFormVisible(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form} name="EditReminder_form" 
          labelCol={{ span: 8 }} 
          wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
          // initialValues={}
        >
          <Form.Item name='RemindPersonID' label='Person to Remind' style={{marginBottom:20}}>{renderDcmPeopleDropdownList(dcmPeopleData)}</Form.Item>

          <Form.Item name='ReminderType' label='Reminder Type' style={{marginBottom:20}}>{renderSysCodes("ReminderType", "Reminder Type")}</Form.Item>

          <Tooltip title="Default is current date/time">
            <Form.Item name="FromDate" label="Date/Time to start reminding"><DatePicker showTime format={format}/></Form.Item>
          </Tooltip>

          <Form.Item name='Urgency' label='Urgency' style={{marginBottom:20}}>{renderSysCodes("UrgencyLevel", "Urgency Level")}</Form.Item>

          <Form.Item name='Notes' label="Notes" style={{marginTop:20, marginBottom:0}}>
            <TextArea style={inputFields}  placeholder='Notes relating to the reminder' rows={4} style={{marginBottom:20}}/>
          </Form.Item>

          <Form.Item name='IsDismissed' valuePropName="checked" label="DISMISS THIS REMINDER?" style={{marginBottom:20}} ><Checkbox /></Form.Item>
        </Form>
      </Modal>
    );
  };

  const onCreateEditReminderForm = (values, title) => {
    setIsLoading(true)
  };


  //=======================================================================
  //  _setEditReminderDataItem
  //=======================================================================
  const _setEditReminderDataItem = async (formData) => {
    let currAction = "AddReminder";
    setIsLoading(false)

    let isDismissed = "false"
    if(formData.IsDismissed) isDismissed = "true"
    console.log(formData.IsDismissed, isDismissed)

    var UserEmail = localStorage.getItem("userEmail")
    var login = UserEmail
    if(!login) login = "10001"

    var remindPersonID = sessionStorage.getItem('personID')
    if(!remindPersonID) remindPersonID = ""
    // console.log(personID)

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setReminder", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": login,
          "claimID": claimHdrData[0].ClaimID.toString(),
          "reminderID": '',
          "remindPersonID": remindPersonID.toString(),
          "reminderType": formData.ReminderType,
          "fromDate": formData.FromDate,
          "urgency": formData.Urgency,
          "notes": formData.Notes,
          "isDismissed": isDismissed
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Reminder API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Saved new Reminder data");
  }


  //=======================================================================
  //  _dismissReminderDataItem
  //=======================================================================
  const _dismissReminderDataItem = async ( reminderID) => {
    let currAction = "Dismiss";

    let isDismissed = "true"
 
    var UserEmail = localStorage.getItem("userEmail")
    var login = UserEmail
    if(!login) login = "10001"

    //Push the blob to API to run in background - setIsLoading NOT set
    await fetch(process.env.REACT_APP_BASE_URL + "setReminder", {
      body: JSON.stringify(
        {
          "action": currAction,
          "loginID": login,
          "claimID": claimHdrData[0].ClaimID.toString(),
          "reminderID": reminderID.toString(),
          "remindPersonID": "",
          "reminderType": "",
          "fromDate": "",
          "urgency": "",
          "notes": "",
          "isDismissed": isDismissed
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => console.log("Add/Edit Reminder API response: ", responseJSON))
    .catch((error) => console.log("Network Error: " + error))
    .finally()

    _getClaim();

    console.log("Dismissed Reminder ", reminderID);
  }


  //=======================================================================
  //  renderDocFiles
  //=======================================================================
  const renderDocFiles = ( records ) => {
    if (!isLoading) {
      return (
        <div style={dataStyles}>
          
          {records.map((line, i) => (
            <div key={i}>
            <table>
              <thead>
                <tr>
                  <td style={{width:'96%'}}>
                    <span style={{color:'gray', fontSize:12, verticalAlign:'bottom'}}>{line.FolderName}<br/></span>
                    <a href={line.FileURL} target='_docshow'>
                      <span style={{color:'blue', fontSize:16, verticalAlign:'top'}}>{line.FileName}</span><br/>
                    </a>
                  </td>
                </tr>
              </thead>
            </table>
            </div>
          ))}
      </div>
      )
    }
  };



  const renderAttachsEmailList = ( records ) => {
    return (
      <Select mode="multiple" allowClear style={{ width: '100%', }}
        placeholder="Select from the dropdown" >
        {records.map((line, i) => <Option key={i} value={line.FileRefURL}>
          {line.FileRef}</Option>)}
    </Select>
    )
  };


  



  //=======================================================================
  //  renderLatestImages
  //=======================================================================
  // const renderLatestImages = ( records ) => {
  //   return (
  //     <div>
  //       {records.map((line, i) => (
  //         <div key={i} style={{width:'100px', float:'left'}}>
  //           <Image
  //             preview={false}
  //             src={line.ThumbURL}
  //             style={{width:'100px', height:'100px'}}
  //           />
  //         </div>
  //       ))}
  //     </div>
  //   )};

   
  //=======================================================================
  //  renderDocFolders
  //=======================================================================
    // const renderDocFolders = ( records ) => {
    //   return (
    //     <div>
    //       <table style={{width: '100%'}} border={0}>
    //         <thead>
    //           <tr>
    // <td style={{width:'10%', textAlign: 'center'}}>
    //   <span style={Object.assign({}, itemStyle.label, itemStyle.gray)}>Files</span>
    // </td>
    // <td style={{width:'2%'}}>
    //   &nbsp;
    // </td>
    // <td style={{width:'80%'}}>
    // <span style={Object.assign({}, itemStyle.label, itemStyle.gray)}>Folder Name</span>
    // </td>
    // //             </tr>
    // //           </thead>
    // //         {records.map((line, i) => (
    // //             <thead key={i}>
    // <tr>
    //     <td style={{width:'10%', textAlign: 'center'}}>
    //       <span style={Object.assign({}, itemStyle.list, itemStyle.blue)}>{line.DocsCount}</span>
    //     </td>
    //     <td style={{width:'2%'}}>
    //       &nbsp;
    //     </td>
    //     <td style={{width:'80%'}}>
    //       <span style={Object.assign({}, itemStyle.list, itemStyle.black)}>{line.FolderName}</span>
    //     </td>
    // </tr>
    //             </thead>
    //         ))}
    //       </table>
    //   </div>
    //   )
    // };


  //=======================================================================
  //  _sendEmail
  //=======================================================================
  const _sendEmail = () => {
    setEmailFormVisible(true)
    console.log({isEmailFormVisible});
    return (
      <div>
        Send email ...
      </div>
    )
  };
  

  //=======================================================================
  const EmailForm = (visible, onCreate, onCancel) => {
    //=======================================================================
    const [form] = Form.useForm();
  
    var ClaimID = claimHdrData[0].ClaimID
    var ClaimRef = claimHdrData[0].ClaimRef
    var UserEmail = localStorage.getItem("userEmail")
  
    var initValues = {
      emFrom: UserEmail,
      emBCC: [UserEmail],
      emSubject: ClaimRef
    }
  
    return (
        <Modal
          onCreate={console.log("onSendEmail")}
          width={1200}
          visible={isEmailFormVisible}
          title="Send an email"
          bodyStyle={{backgroundColor:'#f8f9f9'}}
          okText="Send now" 
          cancelText="Cancel" 
          maskClosable={false}
          onCancel={() => {
            form.resetFields()
            setEmailFormVisible(false)
            }}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                _setEmail(values)
                setEmailFormVisible(false)
              })
              .catch((info) => {
                console.log('Email Form Validate Failed:', info);
              });
          }}
        >
          <Form
            initialValues={initValues}
            form={form} name="Email_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
            style={{backgroundColor:'#f8f9f9'}}
          >
            <Form.Item name='emFrom' label='From' style={{fontWeight:'bold'}}>
              <Input style={inputFields} placeholder="From"/>
            </Form.Item>
  
            <Form.Item name='emTo' required label='To' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesEmailList(claimrolesData)}</Form.Item>
  
            <Form.Item name='emCC' label='cc' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesEmailList(claimrolesData)}</Form.Item>
  
            <Form.Item name='emBCC' label='bcc' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesEmailList(claimrolesData)}</Form.Item>
  
            <Form.Item name='emAttachments' label='Attachments' style={{marginBottom:20, fontWeight:'bold'}}>{renderAttachsEmailList(allDocsData)}</Form.Item>
  
            <Form.Item name='emSubject' required label = "Subject" style={{marginBottom:20, fontWeight:'bold'}} >
              <Input style={{fontWeight:'bold', color:'red'}} />
            </Form.Item>
  
            <Form.Item name='emBody' label = "Body" style={textareaStyle}>
              <TextArea style={inputFields}   rows={12}/>
            </Form.Item>
  
          </Form>
  
        </Modal>
      );
    };
  
  
    function MyComponent() {
      const [items, setItems] = React.useState([{ id: 0, description: 'Old Item' }])
    
      const loadMoreItems = () => {
        setItems([...items, { id: 1, description: 'New Item' }])
      }
    
      return (
        <>
          {items.map((item) => (
            <div key={item.id} value={item.description}>
              <p>{item.description}</p>
            </div>
          ))}
          <button onClick={loadMoreItems}>Load more items</button>
        </>
      )
    }


    //=======================================================================
    //  _setEmail
    //=======================================================================
    const BWU = ( inStr ) => {
      if( !inStr ) {
        return []
      }
      else {
        return inStr
      }    
    }
    
    const FlattenThis = ( inObj ) => {
      var thisItem = inObj.split("|")
      return(thisItem)
    }

    const FlattenAttachments = ( inObj ) => {
      var noAttachs = []
      if(!inObj) return noAttachs

      var allItems = []      
      var rebuildList = inObj.map((item, index) => {
        var thisItem = FlattenThis(item)
        allItems.push(thisItem)
      })
      return(allItems)
    }
  
    const _setEmail = async (formData) => {
      localStorage.setItem('claimId', ClaimID)
      console.log("_setEmail", ClaimID, BWU(formData.emTo), BWU(formData.emCC))
      console.log(FlattenAttachments( formData.emAttachments ))

      let tmpResult
  
      setIsLoading(true)
      await fetch(process.env.REACT_APP_BASE_URL + "sendEmailOAuth", {
        body: JSON.stringify(
          {
            "action": 'Send',
            "emailMessageID": '',
            "loginID": '10001',
            "claimID": ClaimID.toString(),        
            "emailType": 'DS',
            "emailStatus": 'PE',                 
            "emailFrom": formData.emFrom,
            "emailTo": BWU(formData.emTo),
            "emailCC": BWU(formData.emCC),
            "emailBCC": BWU(formData.emBCC),
            "emailSubject": BWU(formData.emSubject),      
            "emailBody": BWU(formData.emBody),
            "attachments": FlattenAttachments(formData.emAttachments),
            "sentDate": '',
            "receivedDate": '',                 
            "hTMLBodyYN": '' 
          }
        ),
             headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
        method: "POST"
      })
      .then((response) => response.json())
      .then((responseJSON) => tmpResult=responseJSON)
      console.log(tmpResult)
      setIsLoading(false)

      setIsLoading(false)
      // setCurrEditClaimData('');
      // _getClaim();

      console.log("email has been sent");
    }
  
    
  //=======================================================================
  //  _sendSMS
  //=======================================================================
  const _sendSMS = () => {
    setSmsFormVisible(true)
    console.log({isSmsFormVisible});
    return (
      <div>
        Send SMS ...
      </div>
    )
  };
  

  //=======================================================================
  const SMSForm = (visible, onCreate, onCancel) => {
  //=======================================================================
  const [form] = Form.useForm();

  var ClaimID = claimHdrData[0].ClaimID
  var ClaimRef = claimHdrData[0].ClaimRef
  var UserEmail = localStorage.getItem("userEmail")

  var initValues = {
    emFrom: UserEmail,
    emBCC: [UserEmail],
    emSubject: ClaimRef
  }

  return (
      <Modal
        onCreate={console.log("onSendSMS")}
        width={1200}
        visible={isSmsFormVisible}
        title="Send an SMS"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Send now" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          form.resetFields()
          setSmsFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setSMS(values)
              setSmsFormVisible(false)
            })
            .catch((info) => {
              console.log('SMS Form Validate Failed:', info);
            });
        }}
      >
        <Form
          initialValues={initValues}
          form={form} name="SMS_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
        >
          <Form.Item name='emTo' required label='To' style={{marginBottom:20, fontWeight:'bold'}}>{renderClaimRolesCellList(claimrolesData)}</Form.Item>

          <Form.Item name='emBody' label = "Message" style={textareaStyle}>
            <TextArea style={inputFields}   rows={12}/>
          </Form.Item>

        </Form>

      </Modal>
    );
  };


  //=======================================================================
  //  _setSMS
  //=======================================================================
  const _setSMS = async (formData) => {
    localStorage.setItem('claimId', ClaimID)

    let toNo = formData.emTo[0]  
    console.log("_setSMS", ClaimID, toNo)

    toNo = toNo.replaceAll(" ", "")
    toNo = toNo.replaceAll("(", "")
    toNo = toNo.replaceAll(")", "")
    toNo = toNo.replaceAll("-", "")
    toNo = toNo.replaceAll("+1", "")
    toNo = "+1" + toNo
    console.log(toNo)

    let tmpResult

    setIsLoading(true)
    await fetch(process.env.REACT_APP_BASE_URL + "sendSMS", {
      body: JSON.stringify(
        {
          "smsBody": formData.emBody,       
          "to": formData.emTo 
        }
      ),
           headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
       "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.text())
    .then((responseJSON) => tmpResult=responseJSON)
    console.log(tmpResult)
    
    if (tmpResult === "Done") {
      setIsLoading(false)
      notification.open({
        message: formData.emSubject,
        description: 'The SMS has been sent.',
        className: 'custom-class',
        duration: 0,
        style: notifySuccessStyle,
      })
      // _getUserInfo()
    }
    else {
      setIsLoading(false)
      notification.open({
        message: 'SMS Set Failed',
        description:"Sorry, there has been a technical problem and the SMS has not been sent.",
        className: 'custom-class',
        duration: 0,
        style: notifyFailStyle,
      })
    }
    setIsLoading(false)
    _getClaim();
    // console.log("SMS has been sent");
  }


  //=======================================================================
  //  claimPositions and positionsHD
  //=======================================================================
  // Setup for HD: good on 1920x1080 at 80% zoom, OK at 100% zoom
  const [hdPositionsData, setHdPositionsData] = React.useState([
    { col: 1, colSpan: 1, rowSpan: 2, order: 1 },
    { col: 2, colSpan: 1, rowSpan: 2, order: 2 },

    { col: 1, colSpan: 2, rowSpan: 2, order: 3 },

    { col: 3, colSpan: 2, rowSpan: 1, order: 4 },
    { col: 3, colSpan: 2, rowSpan: 3, order: 5 },

    { col: 5, colSpan: 2, rowSpan: 4, order: 6 }
  ]);

  
  //=======================================================================
  //  claimPositions and positions4K
  //=======================================================================
  // Setup for HD: good on 1920x1080 at 80% zoom, OK at 100% zoom
  const [ud4kPositionsData, set4kPositionsData] = React.useState([
    { col: 1, colSpan: 1, rowSpan: 2, order: 1 },
    { col: 2, colSpan: 1, rowSpan: 2, order: 2 },

    { col: 1, colSpan: 2, rowSpan: 2, order: 3 },

    { col: 3, colSpan: 2, rowSpan: 1, order: 4 },
    { col: 3, colSpan: 2, rowSpan: 3, order: 5 },

    { col: 5, colSpan: 2, rowSpan: 4, order: 6 }
  ]);

  
  const [positionsData, setPositionsData] = useState(hdPositionsData)


  //=======================================================================
  //  claimTiles and tiles4K
  //=======================================================================
  let claimTiles = [
    { header:<div>{renderTileHeader("Claim Details", "editclaim", "Edit the Claim Details",  
				                            "Edit", "editclaim", "Edit the Claim Details")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderClaimDetails( claimHdrData )}</div> },    

    { header:<div>{renderTileHeader("Medical Details", "editclaim", "Edit the Claim Details",  
 				                            "Edit", "editclaim", "Edit the Claim Details")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightGray)}>{renderClaimMedicalDetails( claimHdrData )}</div> },
    
    { header:<div>{renderTileHeader("Claim Roles", "", "",  
				                            "UserAdd", "addclaimrole", "Add a Claim Role")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightPink)}>{renderClaimRoles( claimrolesData )}</div> },

    { header:<div>{renderTileHeader("Actions Due", "", "",  
				                            "Alert", "addreminder", "Add a Reminder")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightYellow)}>{renderReminders( remindersData )}</div> },

    { header:<div>{renderTileHeader("Service Referrals", "referrals", "View and Edit the full Costs List",  
				                            "", "", "")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderServiceCosts( servicecostsTileData )}</div> },

    // { header:<div>{renderTileHeader("Documents", "docslist", "View and Edit the full Documents List",  
		// 		                            "", "", "")}</div>, 
    //   body: <div style={Object.assign({}, bodyStyles, bgColor.lightGreen)}>{renderDocFiles( docfilesTileData )}</div> },

    { header:<div>{renderTileHeader("Claim Log", "claimlogs", "View and Edit the full Claim Logs List",  
				                            "addclaimlogsbytype", "addclaimlogsbytype", "")}</div>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightBlue)}>{renderLogs( filteredLogs, "claimlogs", "", "" )}</div> }
  ];

  const handleReposition = (e) => {
    setPositionsData(e.value);
    console.log(e.value);
  };


  //======================================================================================
  //  MAIN RENDER
  //======================================================================================
  if (isLoading === true) {
    return (
      <h3>Loading Data...</h3>
    )
  }

  else {
    return (
      <div>
        <div>{claimHeader()}</div>

        <TileLayout
          autoFlow='column'
          columnAlign="justifyUsingWidth"
          rows={6}
          columns={6}
          gap={{ rows: 10, columns: 10, }}
          items={claimTiles}
          positions={positionsData}
          onReposition={handleReposition}
        />

        <EditClaimForm
            visible={isEditClaimFormVisible}
            onCreate={onCreateEditClaimForm}
            onCancel={() => { setEditClaimFormVisible(false); }}
          />

        <ClaimRoleForm
            visible={isClaimRoleFormVisible}
            onCreate={onCreateClaimRoleForm}
            onCancel={() => { setClaimRoleFormVisible(false); }}
          />

        <EditStepForm
            visible={isEditStepFormVisible}
            onCreate={onCreateEditStepForm}
            onCancel={() => { setEditStepFormVisible(false); }}
          />

        <EditLogForm
            visible={isEditLogFormVisible}
            onCreate={onCreateEditLogForm}
            onCancel={() => { setEditLogFormVisible(false); }}
          />

        <EditReminderForm
            visible={isEditReminderFormVisible}
            onCreate={onCreateEditReminderForm}
            onCancel={() => { setEditReminderFormVisible(false); }}
          />

        <EmailForm
            visible={isEmailFormVisible}
            onCreate={console.log("Create Email Form")}
            onCancel={() => { setEmailFormVisible(false); }}
          />

        <SMSForm
            visible={isSmsFormVisible}
            onCreate={console.log("Create SMS Form")}
            onCancel={() => { setSmsFormVisible(false); }}
          />

      </div>
    )
  }

}
export default protectedRoute(ClaimHome)

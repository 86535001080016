import React,{useEffect, useState } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import protectedRoute from './protectedRoute'
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { TileLayout } from "@progress/kendo-react-layout";
import { Image, Upload, Input, Form, Button, Modal,
          Select, notification, message, Tooltip } from 'antd';
import { InboxOutlined, PoweroffOutlined, InfoOutlined } from '@ant-design/icons';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';
import axios from "axios";
import {getUserToken} from './misc/authHelper'


//============================================
// CODE BLOCKS
//============================================
//  HOME(props)
//  useEffect
//  _getUserInfo
//  searchBox
//  resetRecentsData
//  draggerProps
//  CLIENT PRODUCT DATA
//  SYSCODE DATA
//  STYLES
//  logoImgFile
//  userIconsList
//  showLoggedIn
//  searchBox
//  userHeader
//  addNewClaim
//  NewClaimForm
//  _setNewClaimDataItem
//  renderSystemAnnouncements
//  renderRecentsList
//  renderMyRemsList
//  renderComplaintsList
//  renderRemsSummary
//  renderMyWorkStats
//  renderBillingData
//  renderKPIStats
//  homeTiles
//  MAIN RENDER
//============================================


//=======================================================================
//  HOME(props)
//=======================================================================
function Home(props) {
  var claimGuid = uuidv4()

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true)
  const [searchString, setSearchString] = useState('')
  const [claimSearchHdr, setClaimSearchHdr] = useState('')
  const [claimSearchBody, setClaimSearchBody] = useState('')
  const [isNewClaimFormVisible, setNewClaimFormVisible] = useState(false);
  const [isSearchFormVisible, setSearchFormVisible] = useState(false);
  
  const [currNewClaimData, setCurrNewClaimData] = useState('')
  const [currUser, setCurrUser] = useState([])

  const [ClaimID, setClaimID] = useState('')

  const [userName, setUserName] = useState(localStorage.getItem('userEmail'))

  //User homeTiles Data
  const [userHdrData, setUserHdrData] = useState([])
  const [remindersData, setRemindersData] = useState([])
  const [remSummaryData, setRemSummaryData] = useState([])
  const [statsData, setStatsData] = useState([])
  const [myWorkData, setMyWorkData] = useState([])
  const [billingData, setBillingData] = useState([])
  const [dcmPeople, setDcmPeople] = useState([])

  const [recentsData, setRecentsData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [selectData, setSelectData] = useState([])
  const [selectHdr, setSelectHdr] = useState([])
  const [userData, setUserData] = useState()

  const [productData, setProductData] = useState([])

  const { Option } = Select;
  const { TextArea } = Input;
  const { Dragger } = Upload;

  const inputFields = { color:'blue' }


  //=======================================================================
  //  useEffect
  //=======================================================================
  useEffect(() => {
    _getUserInfo()
  },[]);


  //=======================================================================
  //  _getUserInfo
  //=======================================================================
  const _getUserInfo = async () => {
    console.log("Getting Token")

    var UserEmail = localStorage.getItem("userEmail")

    let tmpUserInfo
    setIsLoading(true)
    await fetch(process.env.REACT_APP_BASE_URL + "getUserInfo", {
      body: JSON.stringify(
        {
          loginId: UserEmail,
        }
      ),
      headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
        "accesstoken" :   getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => tmpUserInfo = responseJSON)
    setCurrUser(tmpUserInfo)
    setUserName(tmpUserInfo[0][0].UserName)

    setUserHdrData(tmpUserInfo[0])
  
    setClaimSearchHdr("Announcements")
    setClaimSearchBody(tmpUserInfo[0][0].Announcements)

    setRecentsData(tmpUserInfo[1])
    setSelectData(tmpUserInfo[1])
    setSelectHdr("Recent Claims ...")

    setRemindersData(tmpUserInfo[2])
    setRemSummaryData(tmpUserInfo[3])
    setStatsData(tmpUserInfo[4])

    setSearchData(tmpUserInfo[5])

    // var UserID = "10001"
     // const myworkJSONData = require("../src/data/MyWork_" + UserID + ".json");
    // setMyWorkData(myworkJSONData)
    setMyWorkData(tmpUserInfo[6])

    setProductData(tmpUserInfo[7])

    setBillingData(tmpUserInfo[8])
    setDcmPeople(tmpUserInfo[9])
    
    sessionStorage.setItem('UserName', tmpUserInfo[0][0].UserName)
    sessionStorage.setItem('UserRoleName', tmpUserInfo[0][0].UserRoleName)

    setIsLoading(false)
  }


  //=======================================================================
  //  draggerProps
  //=======================================================================
  var draggerProps = {
    name: 'file',
    multiple: true,
    headers:{ 
      Accept: "multipart/form-data", 
      "accesstoken": getUserToken()
    },
    data:
        {
          lng:'',
          lat:'',
          action:'',
          loginId:"10001",
          claimId:claimGuid,
          folderName:'',
          createdDate:'',
          docFileId:'',
          docFolderId:'',
          notes:'',
        },
    action: process.env.REACT_APP_BASE_URL + 'uploadFile',
    onChange(info) {
      console.log(claimGuid)
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  //=======================================================================
  //  STYLES
  //=======================================================================
  // TILE COLORS
  const white = '#ffffff'
  const pink = '#ffcccc'
  const lightBlue = '#f6f8ff'
  const lightPurple = '#f4ecf7'
  const lightGreen = '#e8f8f5'
  const lightYellow = '#fef9e7' 
  const lightOrange = '#fbe9e7'
  const lightGray = '#f2f4f4'
  const lightSalmon = '#fdf2e9'
  const lightPink = '#fdf8f7'

  const bgColor = {
    white: {backgroundColor: white},
    lightGray: {backgroundColor: lightGray},
    lightYellow: {backgroundColor: lightYellow},
    lightBlue: {backgroundColor: lightBlue},
    lightPink: {backgroundColor: lightPink},
    lightGreen: {backgroundColor: lightGreen}
  };

  const bodyStyles = {
    padding: 0,
    height: '140%',
    marginLeft:-20, marginRight:-20, marginTop:-20, marginBottom:-20
  };

  const tileStyle = {
    width: '90%',
    verticalAlign: 'top',
    marginLeft:20, marginRight:20, marginTop:-20, marginBottom:20
  };
  const sysAnnouncesStyle = {
    backgroundColor: lightGray,
    width: '90%',
    color: 'steelblue',
    fontFamily: 'Helvetica',
    fontSize: '20pt',
    fontWeight: 'normal',
    verticalAlign: 'top',
    marginLeft:20, marginRight:0, marginTop:-20, marginBottom:20
  };
  
  const notifySuccessStyle = {
    backgroundColor:'#C2FFB3',
    width: 600
  }
  const notifyFailStyle = {
      backgroundColor:'#FF7171',
      width: 600
  }

  const textareaStyle = {
    marginBottom:10, 
    fontWeight: 'bold'
    // width:1122
  }


  //=======================================================================
  //  userIconsList
  //=======================================================================
  const iconStyle = {
    color: 'steelblue', 
    fontSize: 20, 
    width: 24
  }

  const userIconsList = () => {
    return (
      <div style={{display:'inline-block', float:'right'}}>
                          <span style={{cursor:'pointer', width:40}} >
                            <Tooltip title="System Help">
                              <InfoOutlined onClick={() => { alert("Help happens here"); }} style={iconStyle} /></Tooltip>
                          </span>
                          <span style={{cursor:'pointer', width:40}}>
                            <Tooltip title="Sign-out">
                              <PoweroffOutlined onClick={() => signOut() } style={iconStyle} /><br/>
                            </Tooltip>
                          </span>
                        </div> 
    )
  }

  
  //=======================================================================
  //  showLoggedIn
  //=======================================================================
  const showLoggedIn = () => {
    return (
      <div style={{color:'black', marginLeft:10, fontSize:16}}>{userName} is logged in&nbsp;</div>
    )
  }


  //=======================================================================
  //  searchBox
  //=======================================================================
  const searchBox = () => {
    return (
      <div style={{fontSize:18}}>
        <Input placeholder="Search ..."
                  onInput={e => resetRecentsData(e.target.value)}
                  allowClear
                  style={{ width: 160, marginRight:10, color:'blue' }} />
      </div>
    )
  };

  const resetRecentsData = (searchString) => {
    //Do this when length of string is 6 starting with "1"
    if ((searchString.length === 6) && !isNaN(searchString) && (searchString.substring(0, 1) == "1")) {
      console.log("claimID fulfilled", searchString)
      setClaimID(searchString)
      localStorage.setItem('claimId', (searchString))
      navigate("/ClaimHome", {
        state: {
          claimId:searchString
        }
      });
    } 

    if(searchString.substring(0, 1) == "*") {
      setSelectData(searchData)
      setSelectHdr("All Claims ...")
    }
    else {
      if (searchString.length >= 3) {
        console.log(searchString)
        localStorage.setItem('searchString', (searchString))
        //Filter out claims that have SearchStr = null => null causing the crash
        const results = searchData.filter(claim => {
          return claim.SearchStr !== null;
        });

        setSelectData(results.filter(claim  => claim.SearchStr.toLowerCase().includes(searchString.toLowerCase())))
        setSelectHdr("Search results ...")
      }
      else
      { 
        setSelectData(recentsData)
        setSelectHdr("Recent Claims ...")
      }
    }
  }

  const SearchForm = ( visible, onCreate, onCancel ) => {
    return (
      <Modal
        onCreate={console.log("onCreateSearch")}
        width={1200}
        visible={isSearchFormVisible}
        title="Select a Claim record ..."
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => { setSearchFormVisible(false) }}
      >
        Search data shows here ...
      </Modal>
    );
  };


  //=======================================================================
  //  userHeader
  //=======================================================================
  const logoImgFile = "https://nstruc.s3.ap-southeast-2.amazonaws.com/logoDCM2.png"
  const logoImgStyle = { width: '200px', height: '60px' };

  const userHeader = () => {
    var version = 'DCM Connect ' + process.env.REACT_APP_VERSION
    if (!isLoading) {
      return (
        <div style={{fontSize:18}}>
          <table style={{verticalAlign: 'top', width: '100%'}} border={0}>
            <thead>
              <tr style={{verticalAlign:'top'}}>
                <td style={{width:'20%', textAlign: 'left'}}>
                <Tooltip title={version}>
                    <Image
                      alt='David Corey Medical'
                      preview={false}
                      src={logoImgFile}
                      style={logoImgStyle}
                    />
                  </Tooltip>
                </td>
                <td style={{width:'10%', textAlign: 'right', marginRight:10, fontSize:18}}>
                  {addNewClaim()}
              </td>
                <td style={{width:'40%', textAlign: 'center'}}>
                  <span style={{color:'steelblue', fontSize:24}}>{userHdrData[0].UserName}</span><br/>
                  <span style={{color:'green', fontSize:18}}>{userHdrData[0].UserRoleName}<br/></span>
                </td>
                <td style={{width:'30%', textAlign: 'right', marginRight:10, fontSize:18}}>
                  {userIconsList()}
                  {searchBox()}
                  {showLoggedIn()}
              </td>
              </tr>
            </thead>
          </table>
      </div>
      )
    }
  }; 


  //=======================================================================
  function signOut() {
  //=======================================================================
    // localStorage.removeItem('userEmail')
    console.log("SignOut Fired From Home")
    localStorage.clear()
    localStorage.clear()
    Auth.signOut()
      .catch(err => console.log('error signing out: ', err))
    navigate("/")
  }


  //=======================================================================
  //  addNewClaim
  //=======================================================================
  const addNewClaim = () => {
    return (
      <div style={{fontSize:18}}>
        <Button style={{height:48, marginTop:10, fontSize:20, marginBottom:4, marginRight:10, color:"white", backgroundColor:"steelblue"}}
          onClick={() => addClaimClicked() }>Add a New Claim record</Button> 
      </div>
    )
  };

  const addClaimClicked = ( ) => {
    setNewClaimFormVisible(true)
    console.log({isNewClaimFormVisible});
    return (
      <div>
        All Good
      </div>
    )
  };

  
  
  //=======================================================================
  //  SYSCODE DATA
  //=======================================================================
  const sysCodesData = require("./data/SysCodes.json");
  const sysCodes = sysCodesData[0];
  const renderSysCodes = ( codeType, label ) => {
    const records = sysCodes.filter( (sysCodes) => sysCodes.xsType === codeType);
    const placeholder = label;
    return (
      <Select showSearch placeholder={placeholder}>
        <Option value=""></Option>
          {records.map((line, i) => <Option key={i} value={line.xsCode}>{line.xsDescr}</Option>)}
      </Select>
    )
  };

  //=======================================================================
  //  CLIENT PRODUCT DATA
  //=======================================================================
  // const clientProductsData = require("./data/ClientProducts.json");
  const clientProductsData = productData

  // console.log(clientProductsData);
  const renderClientProducts = ( records ) => {
    return (
      <Select style={inputFields} showSearch placeholder="Carrier">
        {records.map((line, i) => <Option key={i} value={line.InsurerID}>{line.Product}</Option>)}
    </Select>
    )
  };


  //=======================================================================
  //  NewClaimForm
  //=======================================================================
  const NewClaimForm = ( visible, onCreate, onCancel ) => {
    const [form] = Form.useForm();
    let initFormData = []

    // express all dates as moments
    var mNotifiedDT = moment(initFormData.NotifiedDT);
    initFormData.NotifiedDT = mNotifiedDT;

    return (
      <Modal
        onCreate={console.log("onCreate")}
        width={1200}
        open={isNewClaimFormVisible}
        title="New Claim Details"
        bodyStyle={{backgroundColor:'#f8f9f9'}}
        okText="Save" 
        cancelText="Cancel" 
        maskClosable={false}
        onCancel={() => {
          claimGuid = null
          form.resetFields()
          setNewClaimFormVisible(false)
          }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              _setNewClaimDataItem(values)
              setNewClaimFormVisible(false)
              _getUserInfo()
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          initialValues={{size:'large'}}
          form={form} name="EditClaim_form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
          style={{backgroundColor:'#f8f9f9'}}
        >
          <Form.Item name='dcmInsID' label='Carrier' style={{fontWeight:'bold'}}>
            {renderClientProducts(clientProductsData)}
          </Form.Item>

          <Form.Item label = "Carrier Claim Number" name="ClaimNo" style={{marginBottom:0, fontWeight:'bold'}}>
            <Input style={inputFields} placeholder="Carrier Claim Number" />
          </Form.Item>
          <Form.Item label="Requester/Referrer" style={{marginBottom:0}}>
            <Form.Item name="IA_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IA_Given" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IA_Surname" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Requester/Referrer Contact Details" style={{marginBottom:0}}>
            <Form.Item name="IA_PhoneH" style={{ display: 'inline-block', width: 'calc(20%)' }} >
              <Input style={inputFields} placeholder="Phone (BH)" /> 
            </Form.Item>
            <Form.Item name="IA_Cell" style={{ display: 'inline-block', width: 'calc(20%)' }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="IA_email" style={{ display: 'inline-block', width: 'calc(30%)' }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>  
            <Form.Item name='IA_Position' style={{ display: 'inline-block', width: 'calc(30%)' }} >
              <Input style={inputFields} placeholder="Position" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Injured Worker" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IW_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IW_Given" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IW_Surname" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="IW_PhoneH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Phone (Home)" /> 
            </Form.Item>
            <Form.Item name="IW_Cell" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="IW_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>
          </Form.Item>

          <Form.Item label="Personal Details" style={{marginBottom:0}}>
            <Form.Item name="IW_DOB" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              {/* <DatePicker placeholder='Date of Birth' style={{ display: 'inline-block', width: 'calc(100%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Birth" /> 
            </Form.Item>
            <Form.Item name="IW_Gender" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              {renderSysCodes("Gender", "Gender")}
            </Form.Item>
            <Form.Item name="IW_Height" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
              <Input style={inputFields} placeholder="Height"/> 
            </Form.Item>
            <Form.Item name="IW_Weight" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
              <Input style={inputFields} placeholder="Weight"/> 
            </Form.Item>
          </Form.Item>
          <Form.Item label="Preferences" style={{marginBottom:0}}>
            <Form.Item name="IW_Preferred" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Preferred name" /> 
            </Form.Item>
            <Form.Item name="IW_PrefLang" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Preferred language"/>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Home Address" style={{marginBottom:0}}>
            <Form.Item name="IW_Address1" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 1"/></Form.Item>
            <Form.Item name="IW_Address2" style={{marginBottom:0}}><Input style={inputFields} placeholder="Line 2"/></Form.Item>
              <Form.Item name="IW_City" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="City/Town" /> 
              </Form.Item>
              <Form.Item name="IW_State" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
                <Input style={inputFields} placeholder="State"/>
              </Form.Item>
              <Form.Item name="IW_Zipcode" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} > 
                <Input style={inputFields} placeholder="Zipcode"/> 
              </Form.Item>
            </Form.Item>
          <Form.Item name="IW_Jurisdiction" label="Jurisdiction State"><Input style={inputFields} placeholder="Jurisdiction State"/></Form.Item>

          <Form.Item label="Injured Worker's Contact Person" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IWC_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IWC_Given" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IWC_Surname" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="IWC_PhoneH" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Phone (Home/Work)" /> 
            </Form.Item>
            <Form.Item name="IWC_Cell" style={{ display: 'inline-block', width: 'calc(25%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="IWC_email" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>
          </Form.Item>
          <Form.Item label = "Relationship" name='IWC_Relship'>
            <Input placeholder="Relationship to Injured Worker" style={{ display: 'inline-block', width: 'calc(100%)', color:'blue' }}/>
          </Form.Item>

          <Form.Item label="Injury/Surgery/Discharge Dates" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name='MED_InjuryDT' style={{ display: 'inline-block', width: 'calc(33%)'}}>
              {/* <DatePicker placeholder='Date of Injury' style={{ display: 'inline-block', width: 'calc(100%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Injury" /> 
            </Form.Item>
            <Form.Item name='SurgeryDT' style={{ display: 'inline-block', width: 'calc(33%)'}}>
              {/* <DatePicker placeholder='Date of Surgery' style={{ display: 'inline-block', width: 'calc(100%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Surgery" /> 
            </Form.Item>
            <Form.Item name='DCH_DischargeDT' style={{ display: 'inline-block', width: 'calc(34%)'}}>
              {/* <DatePicker placeholder='Date of Discharge' style={{ display: 'inline-block', width: 'calc(100%)', marginBottom:0 }}/> */}
              <Input style={inputFields} placeholder="Date of Discharge" /> 
            </Form.Item>
          </Form.Item>

          <Form.Item name="IW_HospitalName" label="Hospital" style={{ marginBottom:0, fontWeight:'bold' }} >
            <Input style={inputFields} placeholder="Hospital" /> 
          </Form.Item>
          <Form.Item name="IW_HospitalRoomNo" label="Ward/Room No" style={{ marginBottom:0 }} >
            <Input style={inputFields} placeholder="Ward/Room No" /> 
          </Form.Item>

          <Form.Item label="Status">
            <Form.Item name="IW_DischargeCode" placeholder="Discharge Status" style={{ display: 'inline-block', width: 'calc(50%)', marginBottom:0 }} >
              {renderSysCodes("DischargeStatus", "Discharge status?")}
            </Form.Item>
          </Form.Item>

          <Form.Item label="Nurse Case Manager" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IA2_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IA2_Given" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IA2_Surname" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="IA2_PhoneH" style={{ display: 'inline-block', width: 'calc(20%)' }} >
              <Input style={inputFields} placeholder="Phone (BH)" /> 
            </Form.Item>
            <Form.Item name="IA2_Cell" style={{ display: 'inline-block', width: 'calc(20%)' }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="IA2_email" style={{ display: 'inline-block', width: 'calc(30%)' }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>  
            <Form.Item name='IA2_Position' style={{ display: 'inline-block', width: 'calc(30%)' }} >
              <Input style={inputFields} placeholder="Position/Role" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Field Nurse Case Manager" style={{marginBottom:0, fontWeight:'bold'}}>
            <Form.Item name="IA3_Title" style={{ display: 'inline-block', width: 'calc(20%)', marginBottom:0 }}><Input style={inputFields} placeholder="Mr/Ms etc" /></Form.Item>
            <Form.Item name="IA3_Given" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="First name" /></Form.Item>
            <Form.Item name="IA3_Surname" style={{ display: 'inline-block', width: 'calc(40%)', marginBottom:0 }}><Input style={inputFields} placeholder="Last name" /></Form.Item>
          </Form.Item>
          <Form.Item label="Contact Details" style={{marginBottom:0}}>
            <Form.Item name="IA3_PhoneH" style={{ display: 'inline-block', width: 'calc(20%)' }} >
              <Input style={inputFields} placeholder="Phone (BH)" /> 
            </Form.Item>
            <Form.Item name="IA3_Cell" style={{ display: 'inline-block', width: 'calc(20%)' }} >
              <Input style={inputFields} placeholder="Cell" /> 
            </Form.Item>
            <Form.Item name="IA3_email" style={{ display: 'inline-block', width: 'calc(30%)' }} >
              <Input style={inputFields} placeholder="email" /> 
            </Form.Item>  
            <Form.Item name='IA3_Position' style={{ display: 'inline-block', width: 'calc(30%)' }} >
              <Input style={inputFields} placeholder="Position/Role" />
            </Form.Item>
          </Form.Item>

          <Form.Item label = "Services Requested/Special Instructions" name='IW_Comments' style={textareaStyle}>
            <TextArea style={inputFields} rows={4}/>
          </Form.Item>

          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag one or more files to this area to upload</p>
            <p className="ant-upload-hint">
              Click to select with File Explorer, or just drag-and-drop here (supports single or multi-file uploads)
            </p>
          </Dragger>
          
        </Form>

      </Modal>
    );
  };


  //=======================================================================
  //  _setNewClaimDataItem
  //=======================================================================
  const _setNewClaimDataItem = async (formData) => {
    let tmpResult

    setIsLoading(true)
    await fetch(process.env.REACT_APP_BASE_URL + "dcmSetNewClaimV3", {
      body: JSON.stringify(
        {
          "action": "Save",
          "dcmGuId":claimGuid,
          "dcmLaId":0,
          "dcmInsId":formData.dcmInsID,

          "claimNo":formData.ClaimNo,
          "lossAdjRef":formData.AdjusterRef,

          "ia_Title":formData.IA_Title,
          "ia_Given":formData.IA_Given,
          "ia_Surname":formData.IA_Surname,
          "ia_PhoneH":formData.IA_PhoneH,
          "ia_Cell":formData.IA_Cell,
          "ia_Email":formData.IA_email,
          "ia_Position":formData.IA_Position,

          "iw_Title":formData.IW_Title,
          "iw_Given":formData.IW_Given,
          "iw_Surname":formData.IW_Surname,
          "iw_PhoneH":formData.IW_PhoneH,
          "iw_Cell":formData.IW_Cell,
          "iw_Email":formData.IW_email,

          "iw_Dob":formData.IW_DOB,
          "iw_Gender":formData.IW_Gender,
          "iw_Height":formData.IW_Height,
          "iw_Weight":formData.IW_Weight,
          "iw_Preferred":formData.IW_Preferred,
          "iw_PrefLang":formData.IW_PrefLang,

          "iw_Address1":formData.IW_Address1,
          "iw_Address2":formData.IW_Address2,
          "iw_City":formData.IW_City,
          "iw_State":formData.IW_State,
          "iw_ZipCode":formData.IW_Zipcode,
          "iw_Jurisdiction":formData.IW_Jurisdiction,

          "iwc_Title":formData.IWC_Title,
          "iwc_Given":formData.IWC_Given,
          "iwc_Surname":formData.IWC_Surname,
          "iwc_PhoneH":formData.IWC_PhoneH,
          "iwc_Cell":formData.IWC_Cell,
          "iwc_Email":formData.IWC_email,
          "iwc_Relship":formData.IWC_Relship,

          "med_InjuryDT":formData.MED_InjuryDT,
          "surgeryDT":formData.SurgeryDT,
          "dch_DischargeDT":formData.DCH_DischargeDT,

          "iw_HospitalName":formData.IW_HospitalName,
          "iw_HospitalRoomNo":formData.IW_HospitalRoomNo,
          "iw_DischargeCode":formData.IW_DischargeCode,
          "iw_RushStatCode":"",

          "ia2_Title":formData.IA2_Title,
          "ia2_Given":formData.IA2_Given,
          "ia2_Surname":formData.IA2_Surname,
          "ia2_PhoneH":formData.IA2_PhoneH,
          "ia2_Cell":formData.IA2_Cell,
          "ia2_Email":formData.IA2_email,
          "ia2_Position":formData.IA2_Position,

          "ia3_Title":formData.IA3_Title,
          "ia3_Given":formData.IA3_Given,
          "ia3_Surname":formData.IA3_Surname,
          "ia3_PhoneH":formData.IA3_PhoneH,
          "ia3_Cell":formData.IA3_Cell,
          "ia3_Email":formData.IA3_email,
          "ia3_Position":formData.IA3_Position,

          "iw_Comments":formData.IW_Comments
        }
      ),
      headers: { 
        Accept: "application/json", 
        "Content-Type": "application/json",
        "accesstoken": getUserToken()
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((responseJSON) => tmpResult=responseJSON)
    //console.log(tmpResult)
    if (tmpResult.recordset[0].Result === "Success") {
      setIsLoading(false)
      notification.open({
        message: 'Referral Submitted',
        description: 'The New Claim referral has been saved.',
        className: 'custom-class',
        duration: 0,
        style: notifySuccessStyle,
      })
      _getUserInfo()
    }
    else {
      setIsLoading(false)
      notification.open({
        message: 'Referral Submit Failed',
        description:"Sorry, there has been a technical problem and the details have not been captured by the portal.",
        className: 'custom-class',
        duration: 0,
        style: notifyFailStyle,
      })
    }
    setIsLoading(false)
    claimGuid=null
    // setCurrEditClaimData('');
    console.log("Saved new claim data");
  }

  const onCreateNewClaimForm = (values, title) => {
    setIsLoading(true)
  };
  

  //=======================================================================
  //  renderSystemAnnouncements
  //=======================================================================
  const renderSystemAnnouncements = ( sysAnnounceData ) => {
    return (
      <div style={sysAnnouncesStyle}>
        <br/>
        {sysAnnounceData}
      </div>
    )
  };


  //=======================================================================
  //  renderRecentsList
  //=======================================================================
  const renderRecentsList = ( records ) => {
    if (!isLoading) {
      return (
        <div style={tileStyle}>
          <br/>
          {records.map((line, i) => (
            <div key={i}>
              <table>
                <thead>
                  <tr onClick={() => localStorage.setItem('claimId', line.ClaimID)} style={{height:20, verticalAlign:'top'}}>
                      <td style={{width:'100%'}}>
                          <Link to='/ClaimHome' state={{claimId:line.ClaimID}}>
                            <span style={{color: 'blue',
                                          textAlign:"left", 
                                          marginLeft:0}}>{line.ClaimRef}</span> 
                            &nbsp;
                            <span style={{color: 'green', 
                                          fontWeight: 'normal',
                                          textAlign:"left", 
                                          marginLeft:0,
                                          marginBottom:20}}>{line.IWName}</span><br/><br/>
                          </Link>
                        </td>
                  </tr>
                </thead>
              </table>
            </div>
            )
          )}
        </div>
      )
    }
  };


  //=======================================================================
  //  renderMyRemsList
  //=======================================================================
  const renderMyRemsList = ( records ) => {
    if (!isLoading) {
      return (
        <div style={tileStyle}>
          <br/>
          {records.map((line, i) => (
            <div key={i}>
              <table>
                <thead>
                  <tr onClick={() => localStorage.setItem('claimId', line.ClaimID)} style={{height:20, verticalAlign:'top'}}>
                      <td style={{width:'100%'}}>
                      <Link to='/ClaimHome' state={{claimId:line.ClaimID}}>
                          <span style={{color: 'blue', 
                                        textAlign:"left", 
                                        marginLeft:0}}>{line.ClaimID}</span> 
                          &nbsp;
                          <span style={{color: 'green', 
                                        textAlign:"left", 
                                        marginLeft:0}}>{line.IWName}</span>
                          &nbsp;
                          <span style={{color: 'red', 
                                        textAlign:"left", 
                                        fontWeight:"bold",
                                        marginLeft:0}}>{line.MyRemStatus}</span>
                          &nbsp;
                          <span style={{color: 'red', 
                                        textAlign:"left", 
                                        marginLeft:0}}>{line.MyRemType}</span>
                          <br/><br/>
                        </Link>
                      </td>
                  </tr>
                </thead>
              </table>
            </div>
            )
          )}
        </div>
      )
    }
  };


  //=======================================================================
  //  renderComplaintsList
  //=======================================================================
  // const renderComplaintsList = ( records ) => {
  //   return (
  //     <div style={tileStyle}>
  //       <br/>
  //       {records.map((line, i) => (
  //         <table key={i}>
  //           <thead>
  //             <tr style={{height:20, verticalAlign:'top'}}>
  //                 <td style={{width:'100%'}}>
  //                   <a href='/#/ClaimHome' >
  //                     <span style={{color: 'blue', 
  //                                   textAlign:"left", 
  //                                   marginLeft:0}}>{line.ClaimID}</span> 
  //                     &nbsp;
  //                     <span style={{color: 'maroon', 
  //                                   textAlign:"left",
  //                                   marginLeft:0}}>{line.ComplaintType}</span>
  //                     &nbsp;
  //                     <span style={{color: 'black', 
  //                                   textAlign:"left", 
  //                                   marginLeft:0}}>{line.NotifiedDate}</span>
  //                     &nbsp;
  //                     <span style={{color: 'green', 
  //                                   textAlign:"left", 
  //                                   marginLeft:0}}>{line.PHSurname}, {line.PHPostcode}</span><br/><br/>
  //                   </a>
  //                 </td>
  //             </tr>
  //           </thead>
  //         </table>
  //         )
  //       )}
  //   </div>
  //   )
  // };


  //=======================================================================
  //  renderRemsSummary
  //=======================================================================
  const renderRemsSummary = ( records ) => {
    if (!isLoading) {
      return (
        <div style={tileStyle}>
          <br/>
          <table style={{width: '90%'}} border={0}>
            <thead>
              <tr style={{height:5, verticalAlign:'bottom', borderBottom:'1px solid black'}}>
                <td style={{width:'10%', textAlign: 'center', color:'red'}}>
                  Over-due
                </td>
                <td style={{width:'10%', textAlign: 'center', color:'maroon'}}>
                  Due Now
                </td>
                <td style={{width:'10%', textAlign: 'center', color:'blue'}}>
                  Due Soon
                </td>
                <td style={{width:'10%', textAlign: 'center', color:'gray'}}>
                  Pend-ing
                </td>
                <td style={{width:'58%', textAlign: 'left'}}>
                  Reminder Type
                </td>
              </tr>
            </thead>
            {records.map((line, i) => (
              <thead key={i}>
                <tr>
                  <td style={{width:'9%', textAlign: 'center', color:'red'}}>
                    {line.MyRemOverdue}
                  </td>
                  <td style={{width:'9%', textAlign: 'center', color:'maroon'}}>
                    {line.MyRemDueNow}
                  </td>
                  <td style={{width:'9%', textAlign: 'center', color:'blue'}}>
                    {line.MyRemDueSoon}
                  </td>
                  <td style={{width:'9%', textAlign: 'center', color:'gray'}}>
                    {line.MyRemPending}
                  </td>
                  <td style={{width:'62%'}}>
                    {line.MyRemTypeDescr}
                  </td>
                </tr>
              </thead>
            ))}
          </table>
      </div>
      )
    }
  };


  //=======================================================================
  //  renderMyWorkStats
  //=======================================================================
  const renderMyWorkStats = ( records ) => {
    if (!isLoading) {
      return (
        <div style={tileStyle}>
          <br/>
          <table style={{width: '96%'}} border={0}>
            <thead>
            <tr style={{height:5, verticalAlign:'bottom', borderBottom:'1px solid black'}}>
              <td style={{width:'62%', textAlign: 'left'}}>
                  Work Action Type
                </td>
                <td style={{width:'12%', textAlign: 'center', color:'red'}}>
                  Today
                </td>
                <td style={{width:'12%', textAlign: 'center', color:'blue'}}>
                  Week
                </td>
                <td style={{width:'12%', textAlign: 'center', color:'darkgray'}}>
                  Month
                </td>
              </tr>
              </thead>

              {records.map((line, i) => (
                <thead key={i}>
                  <tr>
                    <td style={{width:'62%'}}>
                      {line.StatDescr} <br/>
                    </td>
                    <td style={{width:'15%', textAlign: 'right', color:'red', verticalAlign: 'top', fontWeight:'bold'}}>
                      {line.StatToday}
                    </td>
                    <td style={{width:'15%', textAlign: 'right', color:'blue', verticalAlign: 'top', fontWeight:'bold'}}>
                      {line.StatWeek}
                    </td>
                    <td style={{width:'15%', textAlign: 'right', color:'darkgray', verticalAlign: 'top', fontWeight:'bold'}}>
                      {line.StatMonth}
                    </td>
                  </tr>
                </thead>
              ))}
          </table>
      </div>
      )
    }
  };


  //=======================================================================
  //  renderBillingData
  //=======================================================================
  const renderBillingData = ( records ) => {
    if (!isLoading) {
      return (
        <div style={tileStyle}>
          <br/>
          <table style={{width: '96%'}} border={0}>
            <thead>
            <tr style={{height:5, verticalAlign:'bottom', borderBottom:'1px solid black'}}>
              <td style={{width:'25%', textAlign: 'left'}}>
                  Category
                </td>
                <td style={{width:'15%', textAlign: 'right', color:'red'}}>
                  Items
                </td>
                <td style={{width:'27%', textAlign: 'right', color:'blue'}}>
                  Billed
                </td>
                <td style={{width:'23%', textAlign: 'right', color:'darkgray'}}>
                  Margin%
                </td>
              </tr>
              </thead>

              {records.map((line, i) => (
                  renderBillingStatsLine(i, line.Category, line.ServiceCounts, line.InvoicedChargesStr, line.DcmMarginStr)
                ))
              }
          </table>
      </div>
      )
    }
  };


  const renderBillingStatsLine = ( i, category, serviceCounts, invoicedChargesStr, dcmMarginStr) => {
    if ( category == "TOTALS" ) {
      return (
        <thead key={i}>
                      <tr>
                        <td style={{verticalAlign: 'top', fontWeight:'bold', borderTop:'1px solid'}}>
                          {category} <br/>
                        </td>
                        <td style={{textAlign: 'right', color:'red', verticalAlign: 'top', fontWeight:'bold', borderTop:'1px solid'}}>
                          {serviceCounts}
                        </td>
                        <td style={{textAlign: 'right', color:'blue', verticalAlign: 'top', fontWeight:'bold', borderTop:'1px solid'}}>
                          {invoicedChargesStr}
                        </td>
                        <td style={{textAlign: 'right', color:'darkgray', verticalAlign: 'top', fontWeight:'bold', borderTop:'1px solid'}}>
                          {dcmMarginStr}
                        </td>
                      </tr>
                    </thead>
          )
        }
        else {
          return (
            <thead key={i}>
                          <tr>
                            <td>
                              {category} <br/>
                            </td>
                            <td style={{textAlign: 'right', color:'red', verticalAlign: 'top', fontWeight:'bold'}}>
                              {serviceCounts}
                            </td>
                            <td style={{textAlign: 'right', color:'blue', verticalAlign: 'top', fontWeight:'bold'}}>
                              {invoicedChargesStr}
                            </td>
                            <td style={{textAlign: 'right', color:'darkgray', verticalAlign: 'top', fontWeight:'bold'}}>
                              {dcmMarginStr}
                            </td>
                          </tr>
                        </thead>
          )
        }
      }



  //=======================================================================
  //  renderKPIStats
  //=======================================================================
  const renderKPIStats = ( records ) => {
    if (!isLoading) {
      return (
        <div style={tileStyle}>
          <br/>
          <table style={{width: '100%'}} border={0}>
            {records.map((line, i) => (
              <thead key={i}>
                <tr>
                  <td style={{width:'15%', textAlign: 'center', color:'blue', verticalAlign: 'top', fontWeight:'bold'}}>
                    {line.StatCount}
                  </td>
                  <td style={{width:'2%'}}>
                    &nbsp;
                  </td>
                  <td style={{width:'80%'}}>
                    {line.StatDescr} <br/>
                  </td>
                </tr>
              </thead>
            ))}
          </table>
      </div>
      )
    }
  };


  //=======================================================================
  //  homeTiles
  //=======================================================================

  const [positionsData, setPositionsData] = React.useState([
    { col: 1, colSpan: 2, rowSpan: 2, order: 1 },
    { col: 1, colSpan: 2, rowSpan: 2, order: 2 },

    { col: 3, colSpan: 2, rowSpan: 6, order: 3 },

    { col: 5, colSpan: 2, rowSpan: 2, order: 4 },
    { col: 5, colSpan: 2, rowSpan: 2, order: 5 },

    { col: 7, colSpan: 2, rowSpan: 6, order: 7 }

  ]);

  const homeTiles = [

    { header:<Link to='/ClaimHome' state={{referer:"Announcements"}}>{claimSearchHdr}</Link>, 
      body: <div style={Object.assign({}, sysAnnouncesStyle, bgColor.white)}>
        <br/>{claimSearchBody}</div> },

    { header:<Link to='/ClaimHome' state={{referer:"BillingStats"}}>Billing Statistics</Link>,  
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightGreen)}>{renderBillingData( billingData )}</div> },   

      
    { header:<Link to='/reminders' >Top 10 Actions required ... click for full list</Link>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightBlue)}>{renderMyRemsList( remindersData )}</div> },


    { header:<Link to='/ClaimHome' >My Reminders Summary</Link>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightBlue)}>{renderRemsSummary( remSummaryData )}</div> },

    { header:<Link to='/ClaimHome' state={{referer:"KPIStats"}}>Key Business Statistics</Link>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.lightPink)}>{renderKPIStats( statsData )}</div> },


    { header:<Link to='/ClaimHome' >{selectHdr}</Link>, 
      body: <div style={Object.assign({}, bodyStyles, bgColor.white)}>{renderRecentsList( selectData )}</div> }


  ];

  const handleReposition = (e) => {
    setPositionsData(e.value);
    console.log(e.value);
  };

 
  //======================================================================================
  //  MAIN RENDER
  //======================================================================================
      if (isLoading === true) {
        return (
          <h3>Loading Data...</h3>
        )
      }

      else {
        return (
          <div>
            <div>{userHeader()}</div>

            <TileLayout
              autoFlow='column'
              columnAlign="justifyUsingWidth"
              rows={8}
              columns={8}
              gap={{ rows: 10, columns: 10 }}
              items={homeTiles}
              positions={positionsData}
              onReposition={handleReposition}
            />
            
            <SearchForm
                visible={isSearchFormVisible}
                onCancel={() => { setSearchFormVisible(false); }}
              />

            <NewClaimForm
                visible={isNewClaimFormVisible}
                onCreate={onCreateNewClaimForm}
                onCancel={() => { setNewClaimFormVisible(false); }}
              />

          </div>
        )
      }

}
export default protectedRoute(Home)
import React from 'react';
import Container from './Container'
import protectedRoute from './protectedRoute'
function Protected() {
  return (
      <Container>
      </Container>
 );
}

export default protectedRoute(Protected)
